/**
 * Calendar
 * @overview Calendar
 * @copyright (c) 2016
 * @author Team Golf
 */
SGF.Application.addModule('calendar-item', function(context) {
  'use strict';
  var config;
  var moduleContainer;
  var content;
  var caddyshack;
  var toggleButton;

  NodeList.prototype.toArray = NodeList.prototype.toArray || function() {
    return Array.prototype.slice.call(this);
  }

  var renderToggleButton = function(expand) {
    var dataTitle = toggleButton.getAttribute('data-title');

    if (expand) {
      toggleButton.setAttribute('title', 'Stäng ' + dataTitle);
      toggleButton.textContent = 'Stäng';
      toggleButton.classList.remove('calendar-item__toggle--closed');
    } else {
      toggleButton.setAttribute('title', 'Läs mer ' + dataTitle);
      toggleButton.textContent = 'Läs mer';
      toggleButton.classList.add('calendar-item__toggle--closed');
    }
  }

  var isClosed = function() {
    return content.classList.contains('calendar-item__content-wrapper--closed');
  }

  var toggleContent = function() {
    if (isClosed()) {
      renderToggleButton(true);
    } else {
      renderToggleButton(false);
    }

    content.classList.toggle('calendar-item__content-wrapper--closed');
  }

  var handlePushState = function(event, element) {
    var pos;
    var url;

    if (window.history && window.history.pushState) {
      if (isClosed()) {
        pos = element.href.indexOf('#');

        if (pos > 0) {
          url = element.href.substring(0, element.href.indexOf('#'));
        } else {
          url = url = element.href;
        }

        window.history.pushState(null, null, url);
      } else {
        window.history.pushState(null, null, element.href);
      }

      event.preventDefault();
    }
  }

  var events = {
    _init: function() {
      var target;
      config = context.getConfig();
      moduleContainer = context.element;
      content = moduleContainer.querySelector('.calendar-item__content-wrapper');
      toggleButton = moduleContainer.querySelector('.calendar-item__toggle');
      caddyshack = context.application.getService('caddyshack');

      moduleContainer.querySelector('.calendar-item__toggle--no-js').classList.remove('calendar-item__toggle--no-js');
      moduleContainer.querySelector('.calendar-item__wrapper--no-js').classList.remove('calendar-item__wrapper--no-js');
      moduleContainer.querySelector('.calendar-item__title--no-js').classList.remove('calendar-item__title--no-js');

      if (document.location.hash) {
        target = moduleContainer.querySelector(document.location.hash);

        if (target) {
          moduleContainer.querySelectorAll('.calendar-item__content-wrapper').toArray().forEach(function(e) {
            e.classList.remove('calendar-item__content-wrapper--closed');
          });

          renderToggleButton(true);
          return;
        }
      }

      moduleContainer.querySelectorAll('.calendar-item__content-wrapper').toArray().forEach(function(e) {
        e.classList.add('calendar-item__content-wrapper--closed');
      });

      renderToggleButton(false);
    },

    _onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'title':
          toggleContent();
          handlePushState(event, element);
          break;
        case 'toggle':
          toggleContent();
          handlePushState(event, element);
          break;
      }
    }
  }

  return {
    init: events._init,
    onclick: events._onclick
  };
});
