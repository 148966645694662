class Playlists extends React.Component {

  constructor(props) {
    super(props)
    this.openVideoPlayer = this.openVideoPlayer.bind(this);
  }

  openVideoPlayer(obj) {
    this.props.openVideoPlayer(obj)
  }

  render() {
    const list = this.props.playlists

    return (
      <main className="golf-play-main">
            {list.items ? list.items.map(item =>
                <SinglePlaylist
                    key={item.id}
                    selectedPlaylist={item.id}
                    playlistName={item.snippet.localized.title}
                    openVideoPlayer={this.openVideoPlayer}
                    showAll={false}
                    appWidth={this.props.appWidth} />
        ) : ''}
      </main>
    )
  }
}
