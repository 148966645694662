/**
 * Main start page
 * @overview Main start page
 * @copyright (c) 2018
 * @author Team Juliett
 */
SGF.Application.addModule('main-start-page', function(context) {
  'use strict';

  let _this;
  let moduleContainer;
  let contentTargetContainer;
  let contentTargetSubContainer;
  let contentTargetContainerClass;
  let contentTargetSubContainerClass;
  let mainMenuContext;

  const preventDefault = function(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
      event.cancelBubble = true;
    }
  }

  const setHistoryState = function(selectedTab, isInit = false) {
    let pathToSet = (document.location.pathname === '/' && isInit) ? '/' : selectedTab.querySelector('a').href;

    if (!window.history.state || pathToSet !== window.history.state.href) {
      let pushStateObject = {
        tabEvent: 'TABCLICK',
        contentRef: selectedTab.dataset.id,
        tabName: selectedTab.querySelector('a').innerHTML,
        href: pathToSet
      };

      history.pushState(pushStateObject, document.title, pathToSet);
    }
  }

  const initPopstateListener = function() {
    window.addEventListener('popstate', function(e) {
      let location = document.location;
      let state = e.state;

      if (state && state.tabEvent === 'TABCLICK') {
        let tabsToClick = moduleContainer.querySelector(`.main-start-page__tabs__tab[data-id="${state.contentRef}"] a`);

        if (tabsToClick)
          getSubStartPageContent(state.contentRef);
      }
    });
  }

  const tabClick = function() {
    let tabsInContext = moduleContainer.querySelectorAll('.main-start-page__tabs__tab');
    let tabsInContextArray = [...tabsInContext];

    tabsInContextArray.map(function(singleTab, index) {
      singleTab.querySelector('a').addEventListener('click', function(event) {
        if (event.target.search.indexOf('epieditmode') === -1) {
          preventDefault(event);

          if (contentTargetContainer.dataset.currentId !== singleTab.dataset.id) {
            let pushState = setHistoryState(singleTab);
            getSubStartPageContent(singleTab.dataset.id, pushState, index);
          }
        }
      });
    });
  }

  const getSubStartPageContent = function(contentRef, pushState, index) {
    moduleContainer.querySelector('.main-start-page__tabs__tab[aria-selected="true"]').removeAttribute('aria-selected');
    moduleContainer.querySelector(`.main-start-page__tabs__tab[data-id="${contentRef}"]`).setAttribute('aria-selected', true);
    contentTargetContainer.classList.add(contentTargetContainerClass + '--loading');
    contentTargetSubContainer.classList.add(contentTargetSubContainerClass + '--loading');

    setMainMenuItemToSelected(index);

    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/GetSubStartPageContentAreaFromId?currentContentId=' + contentRef, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');

    xhr.onload = function(e) {
      let response = JSON.parse(xhr.responseText);
      if (response.StatusCode === 'OK') {
        SGF.Application.stopAll(contentTargetContainer)
        SGF.Application.stopAll(contentTargetSubContainer)

        contentTargetContainer.innerHTML = response.ContentArea;
        contentTargetContainer.dataset.currentId = contentRef;
        contentTargetContainer.classList.remove(contentTargetContainerClass + '--loading');

        contentTargetSubContainer.innerHTML = response.ContentSubArea;
        contentTargetSubContainer.classList.remove(contentTargetSubContainerClass + '--loading');

        SGF.Application.startAll(contentTargetContainer);
        SGF.Application.startAll(contentTargetSubContainer);

        context.broadcast('startpageloaded');
        context.broadcast('updateheaderlinks');
        if (pushState)
          pushState();
      }

      if (response.StatusCode === 'Error' || response.StatusCode === 'Forbidden') {
        console.error(response.StatusText);
        contentTargetContainer.classList.remove(contentTargetContainerClass + '--loading');
        contentTargetContainer.classList.add(contentTargetContainerClass + '--error');
        contentTargetSubContainer.classList.remove(contentTargetSubContainerClass + '--loading');
        contentTargetSubContainer.classList.add(contentTargetSubContainerClass + '--error');
      }
    }

    xhr.onerror = function() {
      contentTargetContainer.classList.remove(contentTargetContainerClass + '--loading');
      contentTargetContainer.classList.add(contentTargetContainerClass + '--error');
      contentTargetSubContainer.classList.remove(contentTargetSubContainerClass + '--loading');
      contentTargetSubContainer.classList.add(contentTargetSubContainerClass + '--error');
    }

    setTimeout(function() {
      xhr.send('currentContentId=' + contentRef);
    }, 500);
  }

  const setMainMenuItemToSelected = function(selectedIndex) {
    [...mainMenuContext].map(function(singleItem, index) {
      if (index === selectedIndex) {
        singleItem.classList.add('navigation-list__list-item--selected');

        if (mainMenuContext[index].querySelector('a.navigation-list__list-item__link--trigger'))
          singleItem.classList.add('navigation-list__list-item--expanded');
      } else {
        singleItem.classList.remove('navigation-list__list-item--selected');
        singleItem.classList.remove('navigation-list__list-item--expanded');
      }
    });
  }

  const prefetchMapData = function() {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/service/club/loadmapdata', true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');

    xhr.onload = function(e) {
      let response = JSON.parse(xhr.responseText);
      if (response.StatusCode === 'failed') {
        console.error(response.StatusText);
      }
    }

    xhr.onerror = function() {
    }

    setTimeout(function() {
      xhr.send();
    }, 500);
  }

  const events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;
      contentTargetContainer = moduleContainer.querySelector('.main-start-page__content-area');
      contentTargetContainerClass = contentTargetContainer ? contentTargetContainer.classList[0] : '';
      contentTargetSubContainer = moduleContainer.querySelector('.main-start-page__sub-content__inner');
      contentTargetSubContainerClass = contentTargetSubContainer ? contentTargetSubContainer.classList[0] : '';
      mainMenuContext = document.querySelectorAll('#navigation .navigation-list:not(.navigation-list--feature) > .navigation-list__list-item');

      tabClick();
      setHistoryState(moduleContainer.querySelector('.main-start-page__tabs__tab[aria-selected="true"]'), true);
      initPopstateListener();
      prefetchMapData();
    },

    _destroy: function() {
      moduleContainer = null;
    }
  }
  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  return {
    init: events._init
  };
});
