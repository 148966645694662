/**
 * Full grid expandable
 * @overview Full grid expandable
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('full-grid-expandable', function(context) {
  var config;

  return {
    init: function() {
      var elements = context.element.querySelectorAll('.full-grid-expandable__list-item');
      var i;

      config = context.getConfig();

      for (i = 0; i < elements.length; i++) {
        elements[i].classList.add('full-grid-expandable__list-item--closed');
        elements[i].setAttribute('aria-hidden', true);
        elements[i].setAttribute('aria-live', 'polite');
      }

      if (window.location.hash) {
        elements = context.element.querySelectorAll('.full-grid-expandable__list-item__heading__content');

        for (i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('href') === window.location.hash) {
            elements[i].classList.add('full-grid-expandable__list-item__heading__content--expanded');
            elements[i].parentNode.nextSibling.classList.remove('full-grid-expandable__list-item--closed');
            elements[i].parentNode.nextSibling.setAttribute('aria-hidden', false);
            break;
          }
        }
      }
    },

    onclick: function(event, element, elementType) {
      var expandableElement;
      var hidden;

      if (element.getAttribute('id').indexOf('faq-') !== -1) {
        event.preventDefault();
      }

      if (elementType === 'expandable-heading') {
        element.classList.toggle('full-grid-expandable__list-item__heading__content--expanded');
        expandableElement = element.parentNode.nextSibling;
        expandableElement.classList.toggle('full-grid-expandable__list-item--closed');

        hidden = expandableElement.getAttribute('aria-hidden');

        if (hidden === 'true') {
          expandableElement.setAttribute('aria-hidden', false);
        } else {
          expandableElement.setAttribute('aria-hidden', true);
        }
      }
    }
  }
});
