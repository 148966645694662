/*
* Education Search Page.
* @overview education-search-page
* @copyright (c) 2021
* @author Team Juliett
*/
SGF.Application.addModule('education-search-filters', function(context) {
  'use strict';

  var config;
  var container = context.element;
  var listContainer = container.querySelector('div.filter__more');
  var button = container.querySelector('.filter_show-all');
  var moduleId;

  const showMoreButtonClass = 'filter_show-all';
  const show = 'show';

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  function showmore() {
    if (listContainer.classList.contains(show)) {
      listContainer.classList.toggle('filter__more-show');
      button.classList.toggle(showMoreButtonClass + '-active');
    }
  }

  const clickEvent = function(event) {
    const target = event.target;

    if (target.tagName === 'BUTTON' && target.classList.contains(showMoreButtonClass)) {
      listContainer.classList.toggle('filter__more-show');
      button.classList.toggle(showMoreButtonClass + '-active');
    }
  };

  return {
    init: function() {
      moduleId = context.element.id;
      config = context.getConfig();
      container.addEventListener('click', clickEvent);
      showmore();
    },

    destroy: function() {
      config = null;
      container = null;
    }
  }
});
