/**
 * Feature Columns FAQ
 * @overview Feature Columns FAQ
 * @copyright (c) 2018
 * @author Team Juliett
 */
SGF.Application.addModule('feature-columns-selected-content-container', function(context) {
  var config;
  var moduleId;

  var container;
  var itemAmount;
  var messageName = 'feature-columns-selected-content-container-loaded';

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  function parseContent() {
    var listItems = container.querySelectorAll('.feature-columns-selected-content-container__list-item');

    for (var i = 0; i < listItems.length; i++) {
      var singleItem = listItems[i];
      var singleItemItems = singleItem.querySelectorAll('.selected-content__list > .selected-content__list__list-item');

      if (singleItemItems.length > itemAmount)
      {
        addExpansionLink(singleItem, singleItemItems);
      }
    }
  }

  function addExpansionLink(container, containerElements) {
    for (var i = 0; i < containerElements.length; i++) {
      if (i >= itemAmount)
      {
        var singleItem = containerElements[i];
        singleItem.classList.add('selected-content__list__list-item--hidden');
      }
    }

    var expansionLink = document.createElement('li');
    expansionLink.classList.add('selected-content__list__expand');
    expansionLink.addEventListener('click', (e) => e.target.parentNode.classList.toggle('selected-content__list--open'));

    container.querySelector('.selected-content__list').appendChild(expansionLink);
  }

  return {
    init: function() {
      moduleId = context.element.id;
      config = context.getConfig();

      container = context.element;
      itemAmount = config.itemAmount;

      parseContent();

      broadcast();
    }
  }
});
