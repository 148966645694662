/**
 * Graph block.
 * @overview Graph block
 * @copyright (c) 2016
 * @author Team Golf
 */
SGF.Application.addModule('graph-block', function(context) {
  'use strict';

  var Graph;
  var graphGuid;
  var graphService;
  var canvas;
  var legend;
  var config;
  var startColor;
  var total;

  var getClosest = function(elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.classList.contains(selector.substr(1))) {
        return elem;
      }
    }

    return false;
  };

  var colors = [
    {
      hex: '#9fcede', // Soft-blue
      highlightHex: '#9fcede',
      rgba: 'rgba(159,206,222,1)',
      highlightRgba: 'rgba(159,206,222,0.8)'
    },
    {
      hex: '#00bdc5', // Teal
      highlightHex: '#00bdc5',
      rgba: 'rgba(0,189,197,1)',
      highlightRgba: 'rgba(0,189,197,0.8)'
    },
    {
      hex: '#82ccba', // Soft-green
      highlightHex: '#82ccba',
      rgba: 'rgba(130,204,186,1)',
      highlightRgba: 'rgba(130,204,186,0.8)'
    },
    {
      hex: '#d7bba3', // Skin
      highlightHex: '#d7bba3',
      rgba: 'rgba(215,187,163,1)',
      highlightRgba: 'rgba(215,187,163,0.8)'
    },
    {
      hex: '#ec8e92', // Peach
      highlightHex: '#ec8e92',
      rgba: 'rgba(236,142,146,1)',
      highlightRgba: 'rgba(236,142,146,0.8)'
    },
    {
      hex: '#f69752', // Orange
      highlightHex: '#f69752',
      rgba: 'rgba(246,151,82,1)',
      highlightRgba: 'rgba(246,151,82,0.8)'
    },
    {
      hex: '#f4e492', // Dirty-yellow
      highlightHex: '#f4e492',
      rgba: 'rgba(244,228,146,1)',
      highlightRgba: 'rgba(244,228,146,0.8)'
    },
    {
      hex: '#ac5e60', // Maroon
      highlightHex: '#ac5e60',
      rgba: 'rgba(172,94,96,1)',
      highlightRgba: 'rgba(172,94,96,0.8)'
    },
    {
      hex: '#004987', // Primary-blue
      highlightHex: '#004987',
      rgba: 'rgba(0,7,135,1)',
      highlightRgba: 'rgba(0,7,135,0.8)'
    },
    {
      hex: '#ffcf00', // Primary-yellow
      highlightHex: '#ffcf00',
      rgba: 'rgba(255,207,0,1)',
      highlightRgba: 'rgba(255,207,0,0.8)'
    },
    {
      hex: '#C9D3D5', // Complementary-grey
      highlightHex: '#C9D3D5',
      rgba: 'rgba(201,211,213,1)',
      highlightRgba: 'rgba(201,211,213,0.8)'
    },
    {
      hex: '#cdcdcd', // Dark-grey
      highlightHex: '#cdcdcd',
      rgba: 'rgba(205,205,205,1)',
      highlightRgba: 'rgba(205,205,205,0.8)'
    }
  ];

  function load() {
    graphService = context.getService('chart-api');
    graphService.load();
  }

  var setup = function() {
    var ctx = canvas.getContext('2d');

    if (isHidden(canvas))
      return false;

    var data;
    var options;
    if (config.type === 'pie') {
      data = getPieData();
      options = getPieOptions();
      Graph = new Chart(ctx).Pie(data, options);
      legend[0].innerHTML = Graph.generateLegend();
    } else if (config.type === 'line') {
      data = getLineAndBarData();
      options = getLineOptions();
      Graph = new Chart(ctx).Line(data, options);
    } else if (config.type === 'bar') {
      data = getLineAndBarData();
      options = getDefaultOptions();
      Graph = new Chart(ctx).Bar(data, options);
    }
  }

  function getDefaultOptions() {
    var el = getClosest(context.getElement(), '.splash-container');
    var scaleLineColor;
    var scaleFontColor;

    if (el && el.classList.contains('splash-container--dark-red')) {
      scaleLineColor = 'rgba(255,255,255,1)';
      scaleFontColor = '#fff';
    } else {
      scaleLineColor = 'rgba(68,68,68,1)';
      scaleFontColor = '#444';
    }

    return {
      responsive: true,
      maintainAspectRatio: false,
      scaleFontFamily: 'BrixSlab-Light,Arial,sans-serif',
      tooltipFontFamily: 'BrixSlab-Light,Arial,sans-serif',
      tooltipTitleFontFamily: 'BrixSlab-Light,Arial,sans-serif',
      scaleLineColor: scaleLineColor,
      scaleFontColor: scaleFontColor,
      scaleFontSize: 14,
      legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\">' +
                      '<% for (var i = 0; i < segments.length; i++){%>' +
                      '<li><span style=\"background-color:<%=segments[i].fillColor%>\">' +
                      '</span><%if(segments[i].label){%><%=((segments[i].value/total)*100).toFixed(1).toString().replace(".", ",") %> % <%=segments[i].label%><%}%>' +
                      '</li><%}%>' +
                      '</ul>'
    }
  }

  function getLineOptions() {
    var options = getDefaultOptions();
    options.scaleBeginAtZero = true;

    return options;
  }

  function getPieOptions() {
    var options = getDefaultOptions();

    options.tooltipTemplate = function(v) {
      var s = '';

      if (v.label) {
        s = s + v.label + ': ';
      }

      s = s + ((parseFloat(v.value) / total) * 100).toFixed(1).toString().replace('.', ',') + ' %';

      return s;
    };

    return options;
  }

  function getLineAndBarData() {
    var data = {
      labels: [],
      datasets: [
        {
          fillColor: colors[startColor].rgba,
          strokeColor: colors[startColor].highlightRgba,
          pointColor: colors[startColor].highlightRgba,
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(220,220,220,1)',
          data: []
        }]
    };

    for (var i = 0; i < config.data.length; i++) {
      for (var label in config.data[i]) {
        data.labels.push(label);
        data.datasets[0].data.push(config.data[i][label]);
      }
    }

    return data;
  }

  function getPieData() {
    var data = [];
    var colorIndex = startColor;
    total = 0;

    config.data.forEach(function(e) {
      for (var label in e) {
        if (e[label]) {
          total = total + parseFloat(e[label]);
        }
      }
    });

    for (var i = 0; i < config.data.length; i++) {
      for (var label in config.data[i]) {
        if (colorIndex >= 12) {
          colorIndex = 0;
        }

        var item = {
          value: config.data[i][label],
          color: colors[colorIndex].rgba,
          highlight: colors[colorIndex].highlightRgba,
          label: label
        }

        data.push(item);
        colorIndex++;
      }
    }

    return data;
  }

  function isHidden(el) {
    return el.offsetParent === null;
  }

  var events = {
    _init: function() {
      graphGuid = context.getElement().getAttribute('data-guid');
      startColor = context.getElement().getAttribute('data-start-color') !== '' ? context.getElement().getAttribute('data-start-color') : 0;
      canvas = context.getElement().getElementsByTagName('canvas')[0];
      legend = context.getElement().getElementsByClassName('graph-block__legend');
      config = context.getConfig(graphGuid);
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'application-init':
          load();
          break;
        case 'chart-api.loaded':
          setup();
          break;
        case 'masonry-grid.click':
          setup();
          break;
      }
    }
  }

  return {
    messages: ['application-init', 'chart-api.loaded', 'masonry-grid.click'],
    init: events._init,
    onmessage: events._onmessage
  };
});
