/**
 * Video block.
 * @overview Video block
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('full-grid-list', function(context) {
  var config;
  var enabled;
  var iframe;

  return {
    init: function() {
      config = context.getConfig();
      enabled = config.printEnabled;
    },

    onclick: function(event, element, elementType) {
      if (elementType === 'button-print') {
        if (!enabled) {
          return;
        }

        if (event.preventDefault) {
          event.preventDefault();
        } else {
          event.returnValue = false;
          event.cancelBubble = true;
        }

        iframe = context.element.querySelector('.full-grid-list__iframe');

        if (!iframe) {
          iframe = document.createElement('iframe');
          iframe.classList.add('full-grid-list__iframe');
          context.element.appendChild(iframe);
        }

        iframe.src = element.getAttribute('href');
      }
    }
  }
});
