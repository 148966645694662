/**
 * Calendar
 * @overview Calendar
 * @copyright (c) 2016
 * @author Team Golf
 */
SGF.Application.addModule('calendar', function(context) {
  'use strict';
  var config;

  var search = function() {
    var url = config.url;
    var i;

    for (i = 0; i < config.d.length; i++) {
      if (url.indexOf('?') === -1) {
        url = url + '?d=' + config.d[i];
      } else {
        url = url + '&d=' + config.d[i];
      }
    }

    return url;
  };

  var searchAdd = function(key, value) {
    config[key] = [value];
    return search();
  };

  var searchRemove = function(key, value) {
    var index = config[key].indexOf(value);

    if (index > -1) {
      config[key].splice(index, 1);
    }

    return search();
  };

  var events = {
    _init: function() {
      config = context.getConfig();
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'facet-change':
          if (config.namespace === data.namespace) {
            if (data.selected) {
              window.location.href = searchAdd(data.name, data.value);
            } else {
              window.location.href = searchRemove(data.name, data.value);
            }
          }

          break;
      }
    }
  }

  return {
    messages: ['facet-change'],
    init: events._init,
    onmessage: events._onmessage
  };
});
