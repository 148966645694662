/**
 * Module for search
 * @overview Search
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('framework-search', function(context) {
  'use strict';

  const MIN_QUERY_LENGTH = 2;

  var _this;
  var moduleContainer;
  var disableAutocomplete;
  var searchUrl;
  var searchElement;
  var isIe9;
  var placeHolder = '';
  var lastAutocompleteSearchQuery;
  var autocompleteHitCount = 0;
  var selectedItemIndex = -1;

  // debounce from underscore.js via http://davidwalsh.name/javascript-debounce-function
  var debounce = function(func, wait, immediate) {
    var timeout;

    return function() {
      var args = arguments;
      var callNow = immediate && !timeout;
      var later = function() {
        timeout = null;

        if (!immediate) {
          func.apply(_this, args);
        }
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(_this, args);
      }
    };
  };

  var preventDefault = function(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
      event.cancelBubble = true;
    }
  };

  var toggleSearchBar = function(show) {
    var fieldset;
    var searchResults;

    if (disableAutocomplete) {
      return;
    }

    fieldset = moduleContainer.querySelector('.header__search__fieldset');
    searchResults = moduleContainer.querySelector('.header__search__form__search-result');

    if (show && autocompleteHitCount > 0) {
      fieldset.classList.add('header__search__fieldset--expanded');
      searchResults.classList.add('header__search__form__search-result--expanded');
      searchResults.setAttribute('aria-expanded', true);
    } else {
      fieldset.classList.remove('header__search__fieldset--expanded');
      searchResults.classList.remove('header__search__form__search-result--expanded');
      searchResults.setAttribute('aria-expanded', false);
    }
  }

  var handleSelection = function(add) {
    var items;

    if (autocompleteHitCount === 0) {
      return;
    }

    items = moduleContainer.querySelectorAll('.header__search__form__search-result-item');

    if (selectedItemIndex < 0) {
      if (add > 0) {
        selectedItemIndex = 0;
      }
    } else {
      if (selectedItemIndex < autocompleteHitCount - 1 || selectedItemIndex === autocompleteHitCount - 1 && add === -1) {
        items[selectedItemIndex].classList.remove('header__search__form__search-result-item--selected');
        items[selectedItemIndex].setAttribute('aria-selected', false);
        selectedItemIndex = selectedItemIndex + add;
      }
    }

    if (selectedItemIndex >= 0) {
      items[selectedItemIndex].classList.add('header__search__form__search-result-item--selected');
      items[selectedItemIndex].setAttribute('aria-selected', true);
    }
  };

  var clearSelection = function() {
    var items;

    if (selectedItemIndex >= 0) {
      items = moduleContainer.querySelectorAll('.header__search__form__search-result-item');
      items[selectedItemIndex].classList.remove('header__search__form__search-result-item--selected');
      items[selectedItemIndex].setAttribute('aria-selected', false);
      selectedItemIndex = -1;
    }
  };

  var navigateToSelection = function() {
    var item = moduleContainer.querySelector('.header__search__form__search-result-item--selected a');
    item.click();
  };

  var createAutocompleteSection = function(list, hits, itemPosition) {
    var section = document.createElement('section');
    var ol = document.createElement('ol');

    // TODO: modifiers
    section.classList.add('header__search__form__search-result-section');

    hits.forEach(function(hit) {
      var li = document.createElement('li');
      var a = document.createElement('a');

      //TODO: modifiers
      li.classList.add('header__search__form__search-result-item');

      if (itemPosition++ === selectedItemIndex) {
        li.classList.add('header__search__form__search-result-item--selected');
        li.setAttribute('aria-selected', true);
      }

      a.href = searchUrl + '?q=' + encodeURIComponent(hit);
      a.textContent = hit;

      li.appendChild(a);
      ol.appendChild(li);
    });

    section.appendChild(ol);
    list.appendChild(section);
  };

  var setAutocompleteData = function(data) {
    var list = moduleContainer.querySelector('.header__search__form__search-result-list');
    var itemPosition;
    autocompleteHitCount = data.hits.length;

    while (list.firstChild) {
      list.removeChild(list.firstChild);
    }

    if (autocompleteHitCount === 0) {
      selectedItemIndex = -1;
    } else {
      itemPosition = 0;
      createAutocompleteSection(list, data.hits, itemPosition);
    }
  };

  var autocompleteSearchDebounce = debounce(function() {
    var xhr;
    var query;
    var trimmedQuery;
    var trimmedLowerQuery;

    if (disableAutocomplete) {
      return;
    }

    query = searchElement.value;
    trimmedQuery = query.trim();
    trimmedLowerQuery = trimmedQuery.toLocaleLowerCase();

    if (trimmedQuery.length <= MIN_QUERY_LENGTH || lastAutocompleteSearchQuery === trimmedQuery) {
      return;
    }

    xhr = new XMLHttpRequest();

    xhr.open('POST', '/service/autocomplete/');
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = function() {
      var data;

      if (xhr.status === 200) {
        data = JSON.parse(xhr.responseText);
        lastAutocompleteSearchQuery = trimmedQuery;
        setAutocompleteData(data);
        toggleSearchBar(true);
      }
    };

    setTimeout(function() {
      xhr.send('query=' + encodeURIComponent(trimmedLowerQuery));
    }, 0);
  }, 100);

  var events = {
    _init: function() {
      var config = context.getConfig();
      var html = document.querySelector('html');
      _this = this;
      moduleContainer = context.element;

      disableAutocomplete = config.disableAutocomplete;
      searchUrl = config.searchUrl;

      searchElement = moduleContainer.querySelector('.header__search__form__search-input--no-js');
      searchElement.classList.remove('header__search__form__search-input--no-js');
      isIe9 = html.classList.contains('ie9');

      if (isIe9) {
        if (searchElement.value === '') {
          placeHolder = searchElement.getAttribute('placeholder');
        }
      }
    },

    _destroy: function() {
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {

      //ie9
      if (elementType === 'search-autocomplete') {
        searchElement.focus();

        preventDefault(event);
      }
    },

    _onkeydown: function(event, element, elementType) {
      var key;

      if (elementType === 'search-input') {
        key = event.witch || event.keyCode;

        // 8 = backspace, 9 = tab, 46 = delete
        if ((key === 8 || key === 46) && searchElement.value === '') {
          preventDefault(event);
        } else if (key === 38) {
          handleSelection(-1);
        } else if (key === 40) {
          handleSelection(1);
        } else if (key === 13 && selectedItemIndex >= 0) {
          preventDefault(event);
        } else if (key === 27 && selectedItemIndex >= 0) {
          toggleSearchBar(false);
          clearSelection();
          preventDefault(event);
        }
      }
    },

    _onkeyup: function(event, element, elementType) {
      var key;
      var value;
      var trimmedValue;

      if (elementType === 'search-input') {
        key = event.witch || event.keyCode;

        if (key === 9 || key === 38 || key === 40) {
          return false;
        } else if (key === 13 && selectedItemIndex >= 0) {
          navigateToSelection();
          preventDefault(event);
        }

        value = event.target.value;
        trimmedValue = value.trim();

        if (trimmedValue !== '') {
          autocompleteSearchDebounce();
        } else {
          if (value === '') {
            toggleSearchBar(false);
          }
        }
      }
    },

    _oninput: function(event, element, elementType) {
      if (elementType === 'search-input') {
        if (event.target.value === '') {
          toggleSearchBar(false);
        }
      }
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'search-toggle':
          if (data.state === 'open' && typeof searchElement !== 'undefined') {
            setTimeout(function() {
              searchElement.focus();
            }, 100);
          }

          break;
        case 'updateheaderlinks':
          var newlinks = document.querySelector('#tempheaderitems');
          var headerUl = document.querySelector('.header__search__shortcuts');

          if (newlinks && headerUl)
            headerUl.innerHTML = newlinks.innerHTML;
      }
    }
  }

  return {
    messages: ['search-toggle', 'updateheaderlinks'],
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick,
    onkeydown: events._onkeydown,
    onkeyup: events._onkeyup,
    oninput: events._oninput,
    onmessage: events._onmessage
  };
});
