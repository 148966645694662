SGF.Application.addModule('site-selection-overlay', function(context) {
  var moduleContainer;
  var hiddenClass = 'site-selection-overlay--hidden';
  var bodyOverflowClass = 'body--hide-overflow';
  var containerOverflowClass = 'page-container--hide-overflow';
  var wrapperOverflowClass = 'page-wrapper--hide-overflow';
  var redirectTarget = 'http://www.svenskgolf.se';
  var body;
  var pageContainer;
  var pageWrapper;

  var closeOverlay = function() {
    moduleContainer.classList.add(hiddenClass);
    body.classList.remove(bodyOverflowClass);

    if (pageContainer !== undefined) {
      pageContainer.classList.remove(wrapperOverflowClass);
    }

    if (pageWrapper !== undefined) {
      pageWrapper.classList.remove(containerOverflowClass);
    }
  }

  return {
    init: function() {
      moduleContainer = context.element;
      body = document.getElementsByTagName('body')[0];
      pageContainer = document.getElementsByClassName('page-container')[0];
      pageWrapper = document.getElementsByClassName('page-wrapper')[0];

      if (localStorage.getItem('hasSiteSelection') === null) {
        moduleContainer.classList.remove(hiddenClass);
        body.classList.add(bodyOverflowClass);

        if (pageContainer !== undefined) {
          pageContainer.classList.add(wrapperOverflowClass);
        }

        if (pageWrapper !== undefined) {
          pageWrapper.classList.add(containerOverflowClass);
        }
      }
    },

    destroy: function() {
      moduleContainer = null;
    },

    onclick: function(event, element, elementType) {

      if (elementType === 'action-redirect') {
        window.location = redirectTarget;
      }

      if (elementType === 'button-close' || elementType === 'action-close') {
        localStorage.setItem('hasSiteSelection', true);
      }

      closeOverlay();
    }
  };
});
