/**
 * Table block.
 * @overview Table block
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('table-block', function(context) {
  var formatNumber = function(value) {
    var n;
    var n1;
    var n2;
    var r;

    if (/^\d{5,}$/.test(value)) {
      value += '';
      n = value.split('.');
      n1 = n[0];
      n2 = n.length > 1 ? ' ' + n[1] : '';
      r = /(\d+)(\d{3})/;

      while (r.test(n1)) {
        n1 = n1.replace(r, '$1' + ' ' + '$2');
      }

      return n1 + n2;
    }

    return value;
  }

  var events = {
    _init: function() {
      var cellItems = context.element.querySelectorAll('.table-block__table__content__item');
      var i;
      var re = /(<a[^>]*>)([\s\S]*?)(<\/a>)/ig;
      var match;
      var content;
      var formattedContent;

      if (cellItems) {
        for (i = 0; i < cellItems.length; i++) {
          content = cellItems[i].innerHTML;
          match = re.exec(content);
          if (match) {
            formattedContent = match[1] + formatNumber(match[2]) + match[3];
          } else {
            formattedContent = formatNumber(content);
          }

          if (formattedContent !== content) {
            cellItems[i].innerHTML = formattedContent;
          }
        }
      }
    }
  }

  return {
    init: events._init
  };
});
