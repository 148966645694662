/**
 * Columns Calendar Block
 * @overview For a masonry Columns Calendar Block
 * @copyright (c) 2018
 * @author Team Juliett
 */

SGF.Application.addModule('feature-columns-calendar-block', function(context) {
  'use strict';

  var config;
  var moduleId;

  var container;
  var containerParent;
  var containerParentClass;
  var containerItems;
  var containerHeading;
  var containerFooterLink;
  var rowHeight;

  var filterItemCount = function() {
    var containerItemsArray = [...containerItems];
    var containerHeadingHeight = containerHeading.offsetHeight || 0;
    var containerFooterLinkHeight = containerFooterLink.offsetHeight || 0;
    var containerHeight = container.offsetHeight - containerFooterLinkHeight - containerHeadingHeight - 30;
    var totalItemHeight = 0;

    for (var i = 0; i < containerItemsArray.length; i++) {
      var currentItem = containerItemsArray[i];
      var totalItemHeight = totalItemHeight + currentItem.offsetHeight;

      if (totalItemHeight > containerHeight) {
        if (rowHeight == 1 && i > 0) {
          currentItem.parentNode.removeChild(currentItem);
        } else if (rowHeight > 1) {
          currentItem.parentNode.removeChild(currentItem)
        }
      }
    }
  }

  return {
    init: function() {
      moduleId = context.element.id;

      config = context.getConfig();

      container = context.element;
      containerParent = container.parentNode;

      rowHeight = config.rowHeight == 'two-row' ? 2 : config.rowHeight == 'three-row' ? 3 : 1;
      containerItems = container.querySelectorAll('.feature-columns-calendar-block__list__list-item');
      containerHeading = document.querySelector('.feature-columns-calendar-block__heading');
      containerFooterLink = document.querySelector('.feature-columns-calendar-block__link');

      containerParentClass = config.parentClass;
      if (containerParentClass)
        containerParent.className = containerParentClass;

      filterItemCount();

      // context.application.broadcast(messageName, {
      //   id: moduleId
      // });
    },

    destroy: function() {
      moduleId = null;
    }
  }
});
