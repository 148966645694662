/**
 * Module for More Golf page menu
 * @overview Mor Golf page menu
 * @copyright (c) 2015
 * @author Team Golf
 */

SGF.Application.addModule('more-golf-page-navigation', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;
  var application = undefined;
  var _this;

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  NodeList.prototype.first = function() {
    return this[0];
  }

  var events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;
      moduleId = context.element.id;
      application = context.application;
    },

    _destroy: function() {
      moduleId = null;
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'navigation-toggle':
          event.preventDefault();
          moduleContainer.classList.toggle('more-golf-page__navigation--mobile-closed');
          break;
      }
    },

    _onmessage: function(name, data) {
      if (name === 'framework-header') {
        if (data.state === 'visible') {
          moduleContainer.classList.remove('more-golf-page__navigation--framework-hidden');
        } else if (data.state === 'hidden') {
          moduleContainer.classList.add('more-golf-page__navigation--framework-hidden');
        }
      } else if (name === 'windowscroll') {
        var offsetY = window.pageYOffset;

        if (offsetY > 0) {
          moduleContainer.classList.add('more-golf-page__navigation--scroll');
        } else {
          moduleContainer.classList.remove('more-golf-page__navigation--scroll');
        }
      }
    }
  }

  return {
    behaviors: ['window-events'],
    messages: ['windowscroll', 'framework-header'],
    init: events._init,
    destroy: events._destroy,
    onmessage: events._onmessage,
    onclick: events._onclick
  };
});
