/**
 * Masonry
 * @overview For a masonry grid
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-grid-container', function(context) {
  'use strict';

  var config;
  var target;
  var options = {
    percentPosition: true,
    gutter: 0,
    transitionDuration: 0
  };
  var Masonry;
  var masonryInstance;
  var itemCount;
  var loadedMessages = [];
  var caddyshack;

  var addDomReadyEvent = function(callback) {
    document.readyState === 'interactive' || document.readyState === 'complete' ? callback() : document.addEventListener('DOMContentLoaded', callback);
  };

  var removeDomReadyEvent = function(callback) {
    document.removeEventListener('DOMContentLoaded', callback);
  }

  function validateMessages() {
    if (loadedMessages.length === itemCount) {
      runLayout();
    }
  }

  function runLayout() {
    masonryInstance.layout();
  }

  return {
    messages: ['masonry-grid-item-loaded', 'masonry-grid-reload'],

    init: function() {
      config = context.getConfig();
      Masonry = context.getGlobal('Masonry');
      target = config.target;
      itemCount = config.items;
      options.columnWidth = config.child;
      caddyshack = context.application.getService('caddyshack');

      if (target !== null && target !== undefined) {
        masonryInstance = new Masonry('#' + context.getElement().getAttribute('id') + ' ' + target, options);
      }

      addDomReadyEvent(runLayout);

      setInterval(function() {
        runLayout();
      }, 1000);
    },

    destroy: function() {
      config = null;
      Masonry = null;
      target = null;
      masonryInstance = null;
      itemCount = null;
      loadedMessages = null;
      removeDomReadyEvent(runLayout);
    },

    onmessage: function(name, data) {
      if (name === 'masonry-grid-item-loaded') {
        loadedMessages.push(data);
        validateMessages();
      }

      if (name === 'masonry-grid-reload') {
        runLayout();
      }
    }
  }
});
