/**
 * Masonry Selected Content
 * @overview For a masonry Selected Content item
 * @copyright (c) 2015
 * @author Team Golf
 */

SGF.Application.addModule('feature-columns-selected-content-item', function(context) {
  'use strict';

  var config;
  var moduleId;

  var container;
  var containerParent;
  var containerParentClass;
  var messageName = 'masonry-grid-item-loaded';

  return {
    init: function() {
      moduleId = context.element.id;

      config = context.getConfig();

      container = context.element;
      containerParent = container.parentNode;

      containerParentClass = config.parentClass;
      if (containerParentClass)
        containerParent.className = containerParentClass;

      // context.application.broadcast(messageName, {
      //   id: moduleId
      // });
    },

    destroy: function() {
      moduleId = null;
    }
  }
});
