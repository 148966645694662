/**
 * Video block.
 * @overview Video block
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-video-block', function(context) {
  var YT;
  var $f;
  var gapi;
  var config;
  var moduleContainer;
  var iframe;
  var iframeId;
  var videoType;
  var hasCanvas;
  var youtubePlayer;
  var vimeoPlayer;
  var initialized;
  var isPlaying;
  var spinnerId;
  var hasSpinner;
  var moduleId;
  var messageName = 'masonry-grid-item-loaded';

  var createPlayButton = function() {
    var container = moduleContainer.querySelector('.masonry-video-block__wrapper');
    var playButton = document.createElement('button');
    var canvas = moduleContainer.querySelector('.masonry-video-block__canvas');

    playButton.appendChild(document.createTextNode('Spela filmen'));
    playButton.classList.add('masonry-video-block__play-button');

    if (playButton.dataset !== undefined) {
      playButton.dataset.type = 'play-button';
    } else {
      playButton.setAttribute('data-type', 'play-button');
    }

    if (canvas) {
      container.insertBefore(playButton, canvas.nextSibling);
    } else {
      container.appendChild(playButton);
    }
  }

  var createPictureElement = function(imageSrc) {
    var container = moduleContainer.querySelector('.masonry-video-block__wrapper');
    var playButton = moduleContainer.querySelector('.masonry-video-block__play-button');
    var picture = document.createElement('picture');
    var img = document.createElement('img');

    picture.classList.add('masonry-video-block__canvas');

    img.classList.add('masonry-video-block__canvas__image');
    img.setAttribute('src', imageSrc);

    //TODO: Extract alt from video?
    img.setAttribute('alt', '');

    picture.appendChild(img);

    if (playButton) {
      container.insertBefore(picture, playButton);
    } else {
      container.appendChild(picture);
    }
  }

  var onYouTubeApiResponse = function(response) {
    var thumbnails;
    var url;

    if (typeof response !== 'undefined'
      && typeof response.result !== 'undefined'
      && typeof response.result.items !== 'undefined'
      && response.result.items.length > 0
      && typeof response.result.items[0].snippet !== 'undefined'
      && typeof response.result.items[0].snippet.thumbnails !== 'undefined') {
      thumbnails = response.result.items[0].snippet.thumbnails;
      if (typeof thumbnails.maxres !== 'undefined') {
        url = thumbnails.maxres.url;
      } else if (typeof thumbnails.medium !== 'undefined') {
        url = thumbnails.medium.url;
      } else if (typeof thumbnails.standard !== 'undefined') {
        url = thumbnails.standard.url;
      }

      if (typeof url !== 'undefined') {
        createPictureElement(url);
      }
    }
  }

  var onYouTubeApiError = function(reason) {
  }

  var createYouTubePicture = function() {
    var videoId = iframe.src.replace(/(http:|https:)?\/\/www.youtube.com\/embed\/(.*)\?.*/ig, '$2');
    var request = gapi.client.youtube.videos.list({
      id: videoId,
      part: 'snippet'
    });

    request.then(onYouTubeApiResponse, onYouTubeApiError);
  }

  var onVimeoApiResponse = function(response) {
    var url;

    if (typeof response !== 'undefined'
      && typeof response.thumbnailUrl !== 'undefined') {
      url = response.thumbnailUrl;
      if (typeof url !== 'undefined') {
        createPictureElement(url);
      }
    }
  }

  var onVimeoApiError = function(reason) {
  }

  var createVimeoPicture = function() {
    var videoId = iframe.src.replace(/(http:|https:)?\/\/player.vimeo.com\/video\/(.*)\?.*/ig, '$2');
    var xhr = new XMLHttpRequest();

    xhr.open('GET', '/services/vimeocanvas/' + videoId);
    xhr.onload = function() {
      if (xhr.status === 200) {
        onVimeoApiResponse(JSON.parse(xhr.responseText));
      } else {
        onVimeoApiError(xhr.status);
      }
    };

    setTimeout(function() {
      xhr.send();
    }, 0);
  }

  var createPicture = function() {
    if (hasCanvas) {
      return;
    }

    if (videoType === 'youtube') {
      createYouTubePicture();
    } else if (videoType === 'vimeo') {
      createVimeoPicture();
    }
  }

  var createSpinner = function() {
    var container = moduleContainer.querySelector('.masonry-video-block__wrapper');
    var spinner = document.createElement('div');
    spinnerId = context.element.id + '-spinner';
    spinner.id = spinnerId;

    if (spinner.dataset !== undefined) {
      spinner.dataset.module = 'spinner';
    } else {
      spinner.setAttribute('data-module', 'spinner');
    }

    spinner.classList.add('masonry-video-block__spinner');
    spinner.classList.add('masonry-video-block__spinner--loading');

    container.appendChild(spinner);

    SGF.Application.start(spinner);
  }

  var hideOverlay = function() {
    var playButton = moduleContainer.querySelector('.masonry-video-block__play-button');
    var canvas = moduleContainer.querySelector('.masonry-video-block__canvas');
    var spinner = moduleContainer.querySelector('.masonry-video-block__spinner');

    playButton.classList.add('masonry-video-block__play-button--playing');
    canvas.classList.add('masonry-video-block__canvas--playing');
    spinner.classList.add('masonry-video-block__spinner--playing');
  }

  var setPlaying = function() {
    isPlaying = true;
    iframe.classList.remove('masonry-video-block__video--awaiting-play');
    hideOverlay();
    context.broadcast('spinner.stop', { id: spinnerId });
  }

  var onPlayerStateChange = function(event) {
    if (event.data === YT.PlayerState.PLAYING) {
      if (!isPlaying) {
        setPlaying();
      }
    }
  }

  var onPlayProgress = function(data, id) {
    if (id === iframeId) {
      if (!isPlaying) {
        setPlaying();
      }
    }
  }

  var onPlayerReady = function() {
    initialized = true;

    if (videoType === 'vimeo') {
      vimeoPlayer.addEvent('playProgress', onPlayProgress);
    }

    createPlayButton();
  }

  var setup = function() {
    if (videoType === 'youtube') {
      if (!youtubePlayer) {
        youtubePlayer = new YT.Player(iframeId, {
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
          }
        });
      }
    }

    if (videoType === 'vimeo') {
      if (!vimeoPlayer) {
        vimeoPlayer = $f(iframe);
        vimeoPlayer.addEvent('ready', onPlayerReady);
      }
    }
  }

  var play = function() {
    var playButton;

    if (!initialized) return;

    if (videoType === 'youtube') {
      youtubePlayer.playVideo();
    }

    if (videoType === 'vimeo') {
      vimeoPlayer.api('play');
    }

    playButton = moduleContainer.querySelector('.masonry-video-block__play-button');
    playButton.classList.add('masonry-video-block__play-button--loading');

    if (!hasSpinner) {
      hasSpinner = true;
      createSpinner();
    }

    context.broadcast('spinner.start', { id: spinnerId });
  }

  var load = function() {
    var playerService;
    var apiService;

    if (videoType === 'youtube') {
      playerService = context.getService('youtube-iframe-api');
      playerService.load();

      if (!hasCanvas) {
        apiService = context.getService('youtube-api');
        apiService.load();
      }
    }

    if (videoType === 'vimeo') {
      playerService = context.getService('vimeo-api');
      playerService.load();
    }
  }

  var events = {
    _init: function() {
      var canvas;
      var images;
      var imagesArray;
      var i;
      config = context.getConfig();
      videoType = config.videoType;
      moduleId = context.element.id;

      if (!window.postMessage || navigator.userAgent.match(/iPhone|iPad|iPod|Android/i) || videoType === 'unknown') {
        return;
      }

      moduleContainer = context.element;

      canvas = moduleContainer.querySelector('.masonry-video-block__canvas-no-js');

      if (canvas) {
        canvas.classList.remove('masonry-video-block__canvas-no-js');
        images = canvas.querySelectorAll('.masonry-video-block__canvas__image-no-js');

        if (images) {
          imagesArray = Array.prototype.slice.call(images);

          for (i = 0; i < imagesArray.length; i++) {
            imagesArray[i].classList.remove('masonry-video-block__canvas__image-no-js');
          }
        }
      }

      hasCanvas = config.hasCanvas;
      iframeId = config.videoInstanceId;
      iframe = moduleContainer.querySelector('#' + iframeId);
      iframe.classList.add('masonry-video-block__video--awaiting-play');
      context.application.broadcast(messageName, { id: moduleId });
    },

    _destroy: function() {
      moduleContainer = null;
      iframe = null;
      youtubePlayer = null;
      vimeoPlayer = null;
      moduleId = null;
    },

    _onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'play-button':
          play();
          break;
      }
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'application-init':
          load();
          break;
        case 'youtube-iframe-api.loaded':
          if (videoType === 'youtube') {
            YT = context.getGlobal('YT');
            setup();
          }

          break;
        case 'vimeo-api.loaded':
          if (videoType === 'vimeo') {
            $f = context.getGlobal('$f');
            setup();
            createPicture();
          }

          break;
        case 'youtube-api.loaded':
          if (videoType === 'youtube') {
            gapi = context.getGlobal('gapi');
            createPicture();
          }

          break;

        case 'full-grid-carousel.update-position':
          if (videoType === 'youtube' && youtubePlayer) {
            youtubePlayer.pauseVideo();
          } else if (videoType === 'vimeo' && vimeoPlayer) {
            vimeoPlayer.api('pause');
          }

          break;
      }
    }
  }

  return {
    messages: ['application-init', 'youtube-iframe-api.loaded', 'vimeo-api.loaded', 'youtube-api.loaded', 'full-grid-carousel.update-position'],
    init: events._init,
    destroy: events._destroy,
    onmessage: events._onmessage,
    onclick: events._onclick
  };
});
