class App extends React.Component {

    constructor(props) {
        super(props)

        this.setCurrentPlaylist = this.setCurrentPlaylist.bind(this);
        this.openVideoPlayer = this.openVideoPlayer.bind(this);
        this.closeVideoPlayer = this.closeVideoPlayer.bind(this);
        this.setScreenSize = this.setScreenSize.bind(this);

        this.state = {
            width: 1000, 
            height: 1000, 
            mobileDevice: false, 
            selectedPlaylist: '', 
            selectedPlaylistName: '', 
            playlists: {}, 
            videoObj: {}
        }
    }

    componentDidMount() {
        var channelPlaylists = VideoPlayerApi.channelPlaylists();
        channelPlaylists.then(value => {
            this.setState({ playlists: value })
        })

        this.setScreenSize()
        window.addEventListener("resize", this.setScreenSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setScreenSize);
    }

    setScreenSize() {
        let w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
            height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

        this.setState({
            width: width,
            height: height,
            mobileDevice: (width <= 820 ? true : false)
        });
    }

    setCurrentPlaylist(id, name) {
        this.setState({
            selectedPlaylist: id, 
            selectedPlaylistName: name
        })
    }

    openVideoPlayer(obj) {
        this.setState({
            videoObj: { ...obj }
        })
    }

    closeVideoPlayer() {
        this.setState({ videoObj: {} })
    }

    isObjectEmpty(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key))
                return false;
        }

        return true;
    }

    render() {
        return (
            <div className="golf-play">
                <Header playlists={this.state.playlists} setPlaylist={this.setCurrentPlaylist} isMobileDevice={this.state.mobileDevice} selectedPlaylist={this.state.selectedPlaylist} />

                {
                    this.state.selectedPlaylist == '' ?
                        <AllPlaylistItems playlists={this.state.playlists} openVideoPlayer={this.openVideoPlayer} appWidth={this.state.width} /> :
                        <main className="golf-play-main"><SinglePlaylist key={this.state.selectedPlaylist} selectedPlaylist={this.state.selectedPlaylist} playlistName={this.state.selectedPlaylistName} openVideoPlayer={this.openVideoPlayer} showAll={true} appWidth={this.state.width} /></main>
                }

                {this.isObjectEmpty(this.state.videoObj) === false ? <VideoPlayer openVideoPlayer={this.openVideoPlayer} closeVideoPlayer={this.closeVideoPlayer} videoobject={this.state.videoObj} /> : ''}
            </div>
        )
    }

}