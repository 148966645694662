SGF.Application.addModule('iframe-module', function(context) {
  'use strict';
  var config;
  var element;
  return {
    destroy: function() {
      if (config && config.blockid && document.getElementById(config.blockid) !== null) {
        element = document.getElementById(config.blockid);
        element.parentNode.removeChild(element);
      }
    },

    messages: ['startpageloaded'],
    onmessage: function(name, data) {
      if (name === 'startpageloaded') {
        config = context.getConfig();
        if (config === undefined || config === null) {
          return;
        }

        if (document.getElementById(config.blockid) !== null) {
          element = document.getElementById(config.blockid);
          element.parentNode.removeChild(element);
        }

        script = document.createElement('script');
        script.id = config.blockid;
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://5p4rk13.com/LiveFeed/850/loaderscript.js';
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }
  };
});
