class PlaylistItem extends React.Component {

    constructor(props) {
        super(props)
        this.openVideoPlayer = this.openVideoPlayer.bind(this);
    }

    openVideoPlayer() {
        let itemObject = { ...this.props.videoObject }
        itemObject.currentPlaylistId = this.props.selectedPlaylist
        itemObject.currentVideoId = this.props.key
        this.props.openVideoPlayer(itemObject)
    }

    render() {
        var currentlyPlayingClass = this.props.currentVideoId === this.props.videoObject.contentDetails.videoId ? 'playing' : ''
        var videoTitle = this.props.videoObject.snippet.title
        var videoDescription = this.props.videoObject.snippet.description
        var videoImageurl =
            this.props.videoObject.snippet.thumbnails ?
                this.props.videoObject.snippet.thumbnails.maxres ? this.props.videoObject.snippet.thumbnails.maxres.url :
                    this.props.videoObject.snippet.thumbnails.high ? this.props.videoObject.snippet.thumbnails.high.url :
                        this.props.videoObject.snippet.thumbnails.medium ? this.props.videoObject.snippet.thumbnails.medium.url : '' : ''

        return (
            <li onClick={() => this.openVideoPlayer()} className={currentlyPlayingClass}>
                <div className="img-wrapper">
                    {videoImageurl ? <img src={videoImageurl} /> : ''}
                </div>
                <div className="title">{videoTitle}</div>
                <div className="description">{videoDescription.length > 120 ? videoDescription.substring(0, 120) + '...' : videoDescription}</div>
            </li>
        )
    }
}