/**
 * Blog page
 * @overview Blog page
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('blog-page', function(context) {
  'use strict';
  var config;

  var search = function() {
    var url = config.url + '?p=1';
    var i;

    for (i = 0; i < config.c.length; i++) {
      url = url + '&c=' + config.c[i];
    }

    for (i = 0; i < config.d.length; i++) {
      url = url + '&d=' + config.d[i];
    }

    return url;
  };

  var searchAdd = function(key, value) {
    config[key].push(value);
    return search();
  };

  var searchRemove = function(key, value) {
    var index;

    if (key === 'c') {
      index = config[key].indexOf(parseInt(value));
    } else {
      index = config[key].indexOf(value);
    }

    if (index > -1) {
      config[key].splice(index, 1);
    }

    return search();
  };

  var events = {
    _init: function() {
      config = context.getConfig();
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'facet-change':
          if (config.namespace === data.namespace) {
            if (data.selected) {
              window.location.search = searchAdd(data.name, data.value);
            } else {
              window.location.search = searchRemove(data.name, data.value);
            }
          }

          break;
      }
    }
  }

  return {
    messages: ['facet-change'],
    init: events._init,
    onmessage: events._onmessage
  };
});
