class Header extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            menuOpen: true
        }
    }

    componentDidMount() {
        this.handleMenuOpenState()
    }

    handleMenuOpenState() {
        this.setState({
            menuOpen: !this.props.isMobileDevice ? false : !this.state.menuOpen
        })
    }

    handleSetPlaylist(id, name) {
        this.setState({
            menuOpen: false
        })
        this.props.setPlaylist(id, name)
    }

    render() {
        const list = this.props.playlists
        let menuClassName = this.props.isMobileDevice && this.state.menuOpen ? ' golf-play-header--open' : ''
        let headerClassName = this.props.isMobileDevice ? 'golf-play-header golf-play-header--mobile' + menuClassName : 'golf-play-header'

        return (
            <header className={headerClassName}>
                {this.props.isMobileDevice ? <button onClick={() => this.handleMenuOpenState()}>Spellistor</button> : ''}
                <ul>
                    <li>Spellistor:</li>
                    <li onClick={() => this.handleSetPlaylist('', '')} className={this.props.selectedPlaylist === '' ? 'on' : ''}>Senaste videor</li>
                    {list.items ? list.items.map(item =>
                        <li key={item.id} onClick={() => this.handleSetPlaylist(item.id, item.snippet.localized.title)} className={this.props.selectedPlaylist === item.id ? 'on' : ''}>
                            {item.snippet.localized.title}
                        </li>
                    ) : ''}
                </ul>
            </header>
        )
    }

}