SGF.Application.addModule('body', function(context) {
  var moduleContainer;

  return {
    init: function() {
      moduleContainer = context.element;
    },

    destroy: function() {
      moduleContainer = null;
    },

    onclick: function() {
      context.application.broadcast('page-click');
    }
  }
});
