SGF.Application.addService('chart-api', function(application) {
  'use strict';

  var initialized;
  var initializedAndReady;

  function load() {
    var timeout;
    var tag;
    var scriptTags = Array.prototype.slice.call(document.getElementsByTagName('script'));
    var existingScriptTag = scriptTags.filter(function(s) {
      return s.src === '//cdnjs.cloudflare.com/ajax/libs/Chart.js/1.1.1/Chart.min.js';
    });

    if (existingScriptTag.length === 0) {
      tag = document.createElement('script');

      tag.src = '//cdnjs.cloudflare.com/ajax/libs/Chart.js/1.1.1/Chart.min.js';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      initialized = true;

      timeout = function() {
        if (typeof Chart == 'function') {
          initializedAndReady = true;
          application.broadcast('chart-api.loaded');
        } else {
          setTimeout(timeout, 100);
        }
      }

      timeout();
    }
  }

  return {
    load: function() {
      if (initialized) {
        if (initializedAndReady) {
          application.broadcast('chart-api.loaded');
        }

        return;
      }

      load();
    }
  };
});
