/**
 * Behavior for Window Events
 * @overview Behavior for Window Events
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addBehavior('window-events', function(application) {
  var caddyshack;

  function attachResizeOmitter() {
    const resizeOmitter = caddyshack.debounce(function() {
      application.broadcast('windowresize');
    }, 250);

    window.addEventListener('resize', resizeOmitter);
  }

  function attachScrollOmitter() {
    const scrollOmitter = caddyshack.debounce(function(args) {
      application.broadcast('windowscroll', args);
    }, 30);

    window.addEventListener('scroll', scrollOmitter);
  }

  function initialize() {
    caddyshack = application.getService('caddyshack');

    attachResizeOmitter();
    attachScrollOmitter();
  }

  return {
    init: function() {
      return initialize();
    }
  }
});
