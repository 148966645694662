/**
 * Blog page
 * @overview Blog page
 * @copyright (c) 2016
 * @author Team Golf
 */
SGF.Application.addModule('blog-page-blogs', function(context) {
  'use strict';
  var initialized;
  var config;
  var moduleContainer;
  var $document;
  var $window;
  var isSmoothScrollSupported;
  var requestAnimationFrame;
  var list;
  var startPosition;
  var scrollWidth;
  var direction;
  var scrolling;
  var elapsedTime;
  var elapsedPercentage;
  var targetPosition;
  var distance;
  var scrollMax;
  var listItemCount;

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  var resize = function() {
    var width = $window.innerWidth;
    var target;
    var isMobile = true;

    if (window.matchMedia) {
      if (window.matchMedia('(min-width: 500px)').matches) {
        isMobile = false;
      }
    } else {
      if (width >= 500) {
        isMobile = false;
      }
    }

    if (isMobile) {
      if (listItemCount >= 3) {
        target = Math.ceil(list.querySelector('.blog-page-blogs__list__list-item').offsetWidth / 2);
      } else {
        target = 0;
      }

      if (isSmoothScrollSupported) {
        list.scrollTo({
          behavior: 'smooth',
          left: target,
          top: 0
        });
      } else {
        list.scrollLeft = target;
      }
    } else {
      if (isSmoothScrollSupported) {
        list.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: 0
        });
      } else {
        list.scrollLeft = 0;
      }
    }
  }

  var setup = function() {
    list = moduleContainer.querySelector('.blog-page-blogs__list');
    listItemCount = list.querySelectorAll('.blog-page-blogs__list__list-item').toArray().length;
    resize();

    if (listItemCount > 3) {
      moduleContainer.querySelectorAll('.blog-page-blogs__navigation-item').toArray().forEach(function(e) {
        e.classList.add('blog-page-blogs__navigation-item--visible');
      });
    }
  }

  var scrollInterval = function() {
    if ((direction === 'left' && (list.scrollLeft >= targetPosition || list.scrollLeft >= scrollMax))
        || (direction === 'right' && list.scrollLeft <= targetPosition)) {
      scrolling = false;
      return;
    }

    elapsedTime += 16;
    elapsedPercentage = (elapsedTime / 200);
    elapsedPercentage = (elapsedPercentage > 1) ? 1 : elapsedPercentage;
    list.scrollLeft = Math.ceil(startPosition + (distance * (elapsedPercentage < 0.5 ? 4 * elapsedPercentage * elapsedPercentage * elapsedPercentage : (elapsedPercentage - 1) * (2 * elapsedPercentage - 2) * (2 * elapsedPercentage - 2) + 1)));
    requestAnimationFrame(scrollInterval);
  }

  var scroll = function() {
    var targetWidth;

    if (startPosition === 0 && direction === 'right') {
      scrolling = false;
      return;
    }

    elapsedTime = 0;
    elapsedPercentage = 0.0;
    scrollMax = (listItemCount - 3) * scrollWidth;

    if (direction === 'left') {
      targetWidth = scrollWidth - (startPosition % scrollWidth);
      targetPosition = startPosition + targetWidth;
      distance = targetWidth;
    } else {
      if (startPosition % scrollWidth === 0) {
        targetWidth = scrollWidth;
      } else {
        targetWidth = (startPosition % scrollWidth);
      }

      targetPosition = startPosition - targetWidth;
      distance = -targetWidth;
    }

    scrolling = true;
    requestAnimationFrame(scrollInterval);
  }

  var events = {
    _init: function() {
      config = context.getConfig();
      moduleContainer = context.element;
      $window = context.getGlobal('window');
      $document = context.getGlobal('document');
      isSmoothScrollSupported = 'scrollBehavior' in $document.documentElement.style;
      setup();
    },

    _onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'button-previous':
          if (initialized) {
            if (!scrolling) {
              startPosition = list.scrollLeft;
              scrollWidth = list.querySelector('.blog-page-blogs__list__list-item').offsetWidth;
              direction = 'right';
              scroll();
            }
          }

          break;
        case 'button-next':
          if (initialized) {
            if (!scrolling) {
              startPosition = list.scrollLeft;
              scrollWidth = list.querySelector('.blog-page-blogs__list__list-item').offsetWidth;
              direction = 'left';
              scroll();
            }
          }

          break;
      }
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'windowresize':
          if (initialized) {
            resize();
          }

          break;
        case 'request-animation-frame.initialized':
          if (!initialized) {
            initialized = true;
            requestAnimationFrame = context.getGlobal('requestAnimationFrame');
          }

          break;
      }
    }
  }

  return {
    behaviors: ['request-animation-frame', 'window-events'],
    messages: ['request-animation-frame.initialized', 'windowresize'],
    init: events._init,
    onclick: events._onclick,
    onmessage: events._onmessage
  };
});
