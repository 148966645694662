/**
 * Module for additional content
 * @overview Additional content
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('additional-content', function(context) {
  'use strict';

  var moduleContainer;
  var button;
  var topButton;
  var top;
  var elementToTrack;
  var fallbackElementToTrack = '.masonry-grid';
  var isBottom;
  var config;

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  NodeList.prototype.first = function() {
    return this[0];
  }

  var setIsBottom = function() {
    if (!isBottom) {
      topButton.classList.remove('additional-content__top-button--is-hidden');
      button.classList.add('additional-content__button--is-bottom');
      topButton.classList.add('additional-content__top-button--is-bottom');

      setTimeout(function() {
        moduleContainer.classList.add('additional-content--is-bottom');
      }, 200);

      isBottom = true;
    }
  }

  var setIsNotBottom = function() {
    if (isBottom) {
      button.classList.remove('additional-content__button--is-bottom');
      topButton.classList.remove('additional-content__top-button--is-bottom');

      setTimeout(function() {
        moduleContainer.classList.remove('additional-content--is-bottom');
        topButton.classList.add('additional-content__top-button--is-hidden');
      }, 200);

      isBottom = false;
    }
  }

  var events = {
    _init: function() {
      moduleContainer = context.element;

      config = context.getConfig();
      topButton = moduleContainer.querySelector('.additional-content__top-button');
      button = moduleContainer.querySelector('.additional-content__button');
      button.setAttribute('data-width', button.offsetWidth);
      topButton.setAttribute('data-width', topButton.offsetWidth);
      topButton.classList.add('additional-content__top-button--is-hidden');

      top = document.querySelector('.page-container');

      elementToTrack = document.querySelector((config.elementToTrack || fallbackElementToTrack));
    },

    _destroy: function() {
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {
      var masonryTop;

      switch (elementType) {
        case 'more':
          event.preventDefault();
          masonryTop = elementToTrack == null ? 0 : elementToTrack.getBoundingClientRect().top;

          if (masonryTop > 0 && masonryTop > window.pageYOffset) {
            elementToTrack.scrollIntoView(true);
          } else {
            window.scrollBy(0, 400);
          }

          break;
        case 'top':
          event.preventDefault();
          top.scrollIntoView(true);
          break;
      }
    },

    _onmessage: function(name, data) {
      if (name === 'windowscroll') {
        var offsetY = window.pageYOffset;
        var browserHeight = window.innerHeight;
        var contentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

        if (offsetY > (contentHeight - browserHeight - 500)) {
          setIsBottom();
        } else {
          setIsNotBottom();
        }
      }
    }
  }

  return {
    behaviors: ['window-events'],
    messages: ['windowscroll'],
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick,
    onmessage: events._onmessage
  };
});
