/**
 * Masonry
 * @overview For a masonry grid
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-grid', function(context) {
  'use strict';

  var config;
  var caddyshack;
  var moduleContainer;
  var container;
  var toggleTrigger;
  var containerItems = {};
  var triggerItems = {};
  var classNames = {};
  var currentlyDisplaying;
  var invokedDisplayItem;

  function setupClassList() {
    classNames.itemClass = config.itemClass;
    classNames.defaultItemClass = config.defaultItemClass;
    classNames.visibleItemClass = config.visibleItemClass;
    classNames.hiddenItemClass = config.hiddenItemClass;
    classNames.filtrationItemClass = config.filtrationItemClass;
    classNames.filtrationActiveItemClass = config.filtrationActiveItemClass;
    classNames.filtrationButtonClass = config.filtrationButtonClass;
    classNames.filtrationButtonOpenClass = config.filtrationButtonOpenClass;
    classNames.filtrationButtonActiveClass = config.filtrationButtonActiveClass;
    classNames.filtrationContainerClass = config.filtrationContainerClass;
    classNames.filtrationContainerHiddenClass = config.filtrationContainerHiddenClass;
  }

  function populateItems() {
    container = moduleContainer.getElementsByClassName(classNames.filtrationContainerClass)[0];
    toggleTrigger = moduleContainer.getElementsByClassName(classNames.filtrationButtonClass)[0];

    var items = moduleContainer.getElementsByClassName(classNames.itemClass);
    containerItems.selectableItems = Array.prototype.slice.call(items);

    var defaultItem = moduleContainer.getElementsByClassName(classNames.defaultItemClass)[0];
    containerItems.defaultItem = defaultItem;

    var activeItem = moduleContainer.getElementsByClassName(classNames.visibleItemClass)[0];
    currentlyDisplaying = activeItem;

    var triggers = moduleContainer.getElementsByClassName(classNames.filtrationItemClass);
    triggerItems = Array.prototype.slice.call(triggers);
  }

  function coordinateFiltration(triggerElement) {
    containerItems.selectableItems.forEach(function(item) {
      item.classList.remove(classNames.visibleItemClass);
      item.classList.add(classNames.hiddenItemClass);
    });

    triggerItems.forEach(function(item) {
      item.classList.remove(classNames.filtrationActiveItemClass);
    });

    filtrationActions.closeFiltrationOptions();

    if (currentlyDisplaying.getAttribute('id') === invokedDisplayItem.getAttribute('id')) {
      containerItems.defaultItem.classList.remove(classNames.hiddenItemClass);
      containerItems.defaultItem.classList.add(classNames.visibleItemClass);

      currentlyDisplaying = containerItems.defaultItem;
      invokedDisplayItem = null;
      toggleTrigger.innerHTML = toggleTrigger.getAttribute('data-label');
      toggleTrigger.classList.remove(classNames.filtrationButtonActiveClass);
      context.application.broadcast('masonry-grid-reload');
      return;
    }

    invokedDisplayItem.classList.remove(classNames.hiddenItemClass);
    invokedDisplayItem.classList.add(classNames.visibleItemClass);
    triggerElement.classList.add(classNames.filtrationActiveItemClass);
    toggleTrigger.innerHTML = triggerElement.getAttribute('data-label');
    toggleTrigger.classList.add(classNames.filtrationButtonActiveClass);
    currentlyDisplaying = invokedDisplayItem;
    invokedDisplayItem = null;
    context.application.broadcast('masonry-grid-reload');
  }

  function manageFiltrationInvocation(targetId, triggerElement) {
    invokedDisplayItem = moduleContainer.querySelectorAll(targetId)[0];
    coordinateFiltration(triggerElement);
  }

  var filtrationActions = {

    toggleFiltrationOptions: function() {
      container.classList.toggle(classNames.filtrationContainerHiddenClass);
      toggleTrigger.classList.toggle(classNames.filtrationButtonOpenClass);
    },

    openFiltrationOptions: function() {
      container.classList.remove(classNames.filtrationContainerHiddenClass);
      toggleTrigger.classList.add(classNames.filtrationButtonOpenClass);
    },

    closeFiltrationOptions: function() {
      container.classList.add(classNames.filtrationContainerHiddenClass);
      toggleTrigger.classList.remove(classNames.filtrationButtonOpenClass);
    }

  }

  return {
    init: function() {
      config = context.getConfig();
      caddyshack = context.application.getService('caddyshack');
      moduleContainer = context.element;
      setupClassList();
      populateItems();
    },

    destroy: function() {
      caddyshack = null;
      config = null;
      moduleContainer = null;
      containerItems = null;
      triggerItems = null;
    },

    onclick: function(event, element, elementType) {
      if (elementType === 'filtration-trigger') {
        event.preventDefault();
        manageFiltrationInvocation(element.getAttribute('href'), element);
        context.broadcast('masonry-grid.click');
      }

      if (elementType === 'expand-filtration') {
        event.preventDefault();
        filtrationActions.toggleFiltrationOptions();
        context.broadcast('masonry-grid.click');
      }
    }
  }
});
