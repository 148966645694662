SGF.Application.addModule('press-share-buttons', function(context) {
  'use strict';

  var config = {};
  var container = null;
  var caddyshack;

  return {
    init: function() {
      context.element.classList.remove('no-js');
      container = context.element;
      caddyshack = context.application.getService('caddyshack');
    },

    destroy: function() {
      config = null;
      container = null;
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'subscribe':
          caddyshack.preventDefault(event);
          context.application.broadcast('cision-signup-toggle');
          break;
      }
    }
  };
});
