/**
 * Module for Tour page header
 * @overview Tour page header
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('tour-page-wrapper', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;
  var application = undefined;
  var _this;

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  NodeList.prototype.first = function() {
    return this[0];
  }

  var events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;
      moduleId = context.element.id;
      application = context.application;
    },

    _destroy: function() {
      moduleId = null;
      moduleContainer = null;
    },

    _onmessage: function(name, data) {
      if (name === 'framework-header') {
        if (data.state === 'visible') {
          moduleContainer.classList.remove('tour-page__page-wrapper--framework-hidden');
        } else if (data.state === 'hidden') {
          moduleContainer.classList.add('tour-page__page-wrapper--framework-hidden');
        }
      } else if (name === 'windowscroll') {
        var offsetY = window.pageYOffset;
        if (offsetY > 0) {
          moduleContainer.classList.add('tour-page__page-wrapper--scroll');
        } else {
          moduleContainer.classList.remove('tour-page__page-wrapper--scroll');
        }
      }
    }
  }

  return {
    behaviors: ['window-events'],
    messages: ['windowscroll', 'framework-header'],
    init: events._init,
    destroy: events._destroy,
    onmessage: events._onmessage
  };
});
