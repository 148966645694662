/**
 * Cookies
 * @overview Cookie alert
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('cookie-warning', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;
  var _this;

  NodeList.prototype.first = function() {
    return this[0];
  }

  var events = {
    _init: function() {
      var cookieValue = '';
      _this = this;

      moduleContainer = context.element;
      moduleId = context.element.id;

      if (document.cookie) {
        cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)acceptCookie\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      }

      if (cookieValue !== '1') {
        moduleContainer.classList.add('cookie-warning--visible');
      }
    },

    _destroy: function() {
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {
      if (elementType === 'cookie-accept') {
        var acceptDate = new Date();
        acceptDate.setTime(acceptDate.getTime() + 330 * 24 * 60 * 60 * 1000);

        document.cookie = 'acceptCookie=1; path=/; expires=' + acceptDate.toUTCString();
        moduleContainer.classList.remove('cookie-warning--visible');
      }
    }
  }
  return {
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick
  };
});
