/**
 * Search page
 * @overview Search page
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('search-page', function(context) {
  'use strict';

  var _this;
  var moduleContainer;

  var preventDefault = function(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
      event.cancelBubble = true;
    }
  };

  var resize = function() {
    var filters = moduleContainer.querySelectorAll('.search-page__filter').toArray();
    var browserWidth = window.innerWidth;

    filters.forEach(function(filter) {
      var heading = filter.querySelector('.search-page__filter__heading');
      var filterContainer = filter.querySelector('.search-page__filter__filters');
      var leftOffset;

      if (browserWidth > 1300) {
        leftOffset = -(Math.floor((filterContainer.offsetWidth - heading.offsetWidth) / 2));
        filterContainer.style.marginLeft = leftOffset + 'px';
      } else {
        filterContainer.style.marginLeft = 'auto';
      }
    });
  };

  var hide = function() {
    moduleContainer.querySelectorAll('.search-page__filter').toArray().forEach(function(filter) {
      var filterContainer = filter.querySelector('.search-page__filter__filters');
      var filterHeading = filter.querySelector('.search-page__filter__heading');
      filterContainer.classList.add('search-page__filter__filters--hidden');
      filterHeading.classList.remove('search-page__filter__heading--expanded');
    });
  }

  var events = {
    _init: function() {
      var headings;
      var filters;
      _this = this;
      moduleContainer = context.element;

      headings = moduleContainer.querySelectorAll('.search-page__filter__heading--no-js').toArray();

      headings.forEach(function(filter) {
        filter.classList.remove('search-page__filter__heading--no-js');
      });

      filters = moduleContainer.querySelectorAll('.search-page__filter__filters--no-js').toArray();

      filters.forEach(function(filter) {
        filter.classList.remove('search-page__filter__filters--no-js');
      });

      resize();

      filters = moduleContainer.querySelectorAll('.search-page__filter').toArray();

      filters.forEach(function(filter) {
        var filterContainer = filter.querySelector('.search-page__filter__filters');
        filterContainer.classList.add('search-page__filter__filters--hidden');
      });
    },

    _destroy: function() {
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {
      var filters;
      var isHidden;

      if (elementType === 'toggle-filter') {
        event.stopPropagation();
        filters = element.parentNode.querySelector('.search-page__filter__filters');
        isHidden = filters.classList.contains('search-page__filter__filters--hidden');
        moduleContainer.querySelectorAll('.search-page__filter').toArray().forEach(function(filter) {
          var filterContainer = filter.querySelector('.search-page__filter__filters');
          filterContainer.classList.add('search-page__filter__filters--hidden');
        });

        if (isHidden) {
          filters.classList.remove('search-page__filter__filters--hidden');
          resize();
        }

        element.classList.toggle('search-page__filter__heading--expanded');
      } else if (elementType === 'content-type-filter' || elementType === 'category-filter') {
        if (moduleContainer.tagName.toLocaleLowerCase() === 'form') {
          setTimeout(function() {
            moduleContainer.submit();
          }, 100);
        }
      }
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'windowresize':
          resize();
          break;
        case 'page-click':
          hide();
          break;
      }
    }
  }

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  return {
    behaviors: ['window-events'],
    messages: ['windowresize', 'page-click'],
    init: events._init,
    onmessage: events._onmessage,
    destroy: events._destroy,
    onclick: events._onclick
  };
});
