/**
 * Masonry FAQ
 * @overview Full grid expandable
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-faq', function(context) {
  var config;
  var moduleId;

  var messageName = 'masonry-grid-item-loaded';

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  return {
    init: function() {
      var elements = context.element.querySelectorAll('.masonry-faq__list-item');
      var i;
      moduleId = context.element.id;
      config = context.getConfig();

      for (i = 0; i < elements.length; i++) {
        elements[i].classList.add('masonry-faq__list-item--closed');
        elements[i].setAttribute('aria-hidden', true);
        elements[i].setAttribute('aria-live', 'polite');
      }

      broadcast();
    },

    onclick: function(event, element, elementType) {
      var expandableElement;
      var hidden;

      if (elementType === 'expandable-heading') {
        event.preventDefault();

        element.classList.toggle('masonry-faq__list-item__heading__content--expanded');
        expandableElement = element.parentNode.nextSibling;
        expandableElement.classList.toggle('masonry-faq__list-item--closed');

        hidden = expandableElement.getAttribute('aria-hidden');

        if (hidden === 'true') {
          expandableElement.setAttribute('aria-hidden', false);
        } else {
          expandableElement.setAttribute('aria-hidden', true);
        }

        context.application.broadcast('masonry-grid-reload');
      }
    }
  }
});
