SGF.Application.addModule('cision-signup', function(context) {
  'use strict';

  var config = {};
  var container = null;
  var caddyshack;
  var originalContent;

  NodeList.prototype.toArray = NodeList.prototype.toArray || function() {
    return Array.prototype.slice.call(this);
  }

  function showSuccess() {
    var content = container.querySelector('.cision-signup__content');
    var success = container.querySelector('.cision-signup__success');
    var labels = container.querySelectorAll('.cision-signup__label');
    var buttons = container.querySelectorAll('.cision-signup__submit');
    content.classList.add('cision-signup__content--disabled');
    success.classList.remove('cision-signup__success--disabled');

    labels.toArray().forEach(function(e) {
      e.classList.add('cision-signup__label--disabled');
    });

    buttons.toArray().forEach(function(e) {
      e.classList.add('cision-signup__submit--disabled');
    });
  }

  function retry() {
    var content = container.querySelector('.cision-signup__content');
    var error = container.querySelector('.cision-signup__error');
    var labels = container.querySelectorAll('.cision-signup__label');
    var buttons = container.querySelectorAll('.cision-signup__submit');
    var name = document.getElementById('cisionSignupName');
    content.classList.remove('cision-signup__content--disabled');
    error.classList.add('cision-signup__error--disabled');

    labels.toArray().forEach(function(e) {
      e.classList.remove('cision-signup__label--disabled');
    });

    buttons.toArray().forEach(function(e) {
      e.classList.remove('cision-signup__submit--disabled');
    });

    name.focus();
  }

  function showFailed() {
    var content = container.querySelector('.cision-signup__content');
    var error = container.querySelector('.cision-signup__error');
    var labels = container.querySelectorAll('.cision-signup__label');
    var buttons = container.querySelectorAll('.cision-signup__submit');
    content.classList.add('cision-signup__content--disabled');
    error.classList.remove('cision-signup__error--disabled');

    labels.toArray().forEach(function(e) {
      e.classList.add('cision-signup__label--disabled');
    });

    buttons.toArray().forEach(function(e) {
      e.classList.add('cision-signup__submit--disabled');
    });
  }

  return {
    init: function() {
      context.element.classList.remove('no-js');
      container = context.element;
      caddyshack = context.application.getService('caddyshack');

      if (/[?&]response=failed/.test(location.search)) {
        showFailed();
        container.classList.add('cision-signup--expanded');
      } else if (/[?&]response=success/.test(location.search)) {
        showSuccess();
        container.classList.add('cision-signup--expanded');
      }
    },

    destroy: function() {
      config = null;
      container = null;
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'close':
          caddyshack.preventDefault(event);
          container.classList.remove('cision-signup--expanded');
          break;
        case 'retry':
          caddyshack.preventDefault(event);
          retry();
          break;
      }
    },

    onmessage: function(name, data) {
      switch (name) {
        case 'cision-signup-toggle':
          if (!container.classList.contains('cision-signup--expanded')) {
            var el = document.getElementById('cisionSignupName');
            el.focus();
          }

          container.classList.toggle('cision-signup--expanded');
          break;
      }
    },

    messages: ['cision-signup-toggle']
  };
});
