/**
 * Feature Columns FAQ
 * @overview Feature Columns FAQ
 * @copyright (c) 2018
 * @author Team Juliett
 */
SGF.Application.addModule('feature-columns-faq', function(context) {
  var config;
  var moduleId;

  var container;
  var containerParent;
  var containerParentClass;
  var overlay;
  var messageName = 'feature-columns-faq-loaded';

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  function toggleOverlay() {
    var openFaqItems = document.querySelectorAll('.feature-columns-faq__list-item__heading__content--expanded');

    if (openFaqItems.length == 0 && overlay) {
      containerParent.classList.remove('feature-columns-block__column--overflow-visible');

      overlay.parentNode.removeChild(overlay);
      overlay = null;
    } else if (openFaqItems.length == 1 && !overlay) {
      containerParent.classList.add('feature-columns-block__column--overflow-visible');

      overlay = document.createElement('div');
      overlay.classList.add('feature-columns-faq-overlay');

      overlay.addEventListener('click', function() {
        var openItem = document.querySelector('.feature-columns-faq__list-item__heading__content--expanded');
        if (openItem) {
          containerParent.classList.remove('feature-columns-block__column--overflow-visible');
          toggleSelectedItem(openItem);
          overlay.parentNode.removeChild(overlay);
          overlay = null;
        }
      });

      document.body.appendChild(overlay);
    }
  }

  function toggleSelectedItem(element) {
    var siblingElement = element.parentNode.nextSibling;
    var openHidden = siblingElement.getAttribute('aria-hidden');

    element.classList.toggle('feature-columns-faq__list-item__heading__content--expanded');
    siblingElement.classList.toggle('feature-columns-faq__list-item--closed');

    if (openHidden === 'true') {
      siblingElement.setAttribute('aria-hidden', false);
    } else {
      siblingElement.setAttribute('aria-hidden', true);
    }
  }

  return {
    init: function() {
      var elements = context.element.querySelectorAll('.feature-columns-faq__list-item');
      var i;
      moduleId = context.element.id;
      config = context.getConfig();

      container = context.element;
      containerParent = container.parentNode;

      containerParentClass = config.parentClass;
      if (containerParentClass)
        containerParent.className = containerParentClass;

      for (i = 0; i < elements.length; i++) {
        elements[i].classList.add('feature-columns-faq__list-item--closed');
        elements[i].setAttribute('aria-hidden', true);
        elements[i].setAttribute('aria-live', 'polite');
      }

      broadcast();
    },

    onclick: function(event, element, elementType) {
      var expandableElement;
      var hidden;

      if (elementType === 'expandable-heading') {
        event.preventDefault();

        var openItem = document.querySelector('.feature-columns-faq__list-item__heading__content--expanded');
        if (openItem && openItem != element) {
          toggleSelectedItem(openItem);
        }

        toggleSelectedItem(element);
        toggleOverlay();

        // context.application.broadcast('masonry-grid-reload');
      }
    }
  }
});
