/**
 * App initialization
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et ante at justo fringilla lobortis. Aliquam id commodo ipsum. Duis bibendum turpis eget orci interdum dictum. Sed urna erat, ornare sit amet massa et, rhoncus efficitur sapien. Aliquam non odio lobortis, malesuada lectus at, eleifend magna. Ut sit amet dapibus est, vel pretium eros. Phasellus ac massa consectetur, fringilla sem in, egestas nibh. Sed vel porttitor neque. Nam tortor ipsum, finibus quis pellentesque sit amet, porta ut urna. Maecenas placerat pellentesque massa, nec interdum velit rhoncus vel.
 * @overview App initialization _after_ components are required
 * @copyright (c) 2015
 * @author Torbjörn Walther
 */
(function() {
  SGF.Application.on('error', function(event) {
    if (typeof console !== 'undefined') {
      console.log(event);
    }
  });

  SGF.Application.init({
    debug: true
  });
  SGF.Application.broadcast('application-init');
})();

function onYouTubeIframeAPIReady() {
  SGF.Application.broadcast('youtube-iframe-api.loaded');
}

function onYouTubeDataApiReady() {
  gapi.client.setApiKey('AIzaSyDfDayptb2TEjiF7hyMw20cevlXMvniKPA');
  gapi.client.load('youtube', 'v3').then(function() {
    SGF.Application.broadcast('youtube-api.loaded');
  });
}

function onGoogleMapsDataApiReady() {
  SGF.Application.broadcast('googlemaps-api.loaded');
}
