/*
* Education Page.
* @overview education-page
* @copyright (c) 2020
* @author Team Juliett
*/
SGF.Application.addModule('education-page-list', function(context) {
  'use strict';

  var config;
  var container = context.element;
  var listContainer = container.querySelector('dl.education-page__opportunity-list');
  var containerParent;
  var moduleId;
  var backgroundImage;
  var backgroundColor;
  var containerType;
  var containerParentClass;
  var messageName = 'education-page-list-loaded';

  const listLoadingClassName = listContainer.getAttribute('data-loading');
  const triggerClassName = listContainer.getAttribute('data-expand-trigger');
  const panelClassName = listContainer.getAttribute('data-expand-area');
  const allowMultiple = listContainer.hasAttribute('data-allow-multiple');
  const allowToggle = (allowMultiple) ? allowMultiple : listContainer.hasAttribute('data-allow-toggle');
  const triggers = Array.prototype.slice.call(listContainer.querySelectorAll(`.${triggerClassName}`));
  const panels = Array.prototype.slice.call(listContainer.querySelectorAll(`.${panelClassName}`));
  const showMoreButtonClass = 'education-page__opportunity-list__show-all';

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  const clickEvent = function(event) {
    const target = event.target;

    if (listLoadingClassName && listContainer.classList.contains(listLoadingClassName)) {
      event.preventDefault();
      console.log('Loading...');
      return;
    }

    if (target.classList.contains(triggerClassName)) {
      const isExpanded = target.getAttribute('aria-expanded') === 'true';
      const active = listContainer.querySelector('[aria-expanded="true"]');

      if (!allowMultiple && active && active !== target) {
        active.setAttribute('aria-expanded', 'false');
        document.getElementById(active.getAttribute('aria-controls')).setAttribute('hidden', '');

        if (!allowToggle) {
          active.removeAttribute('aria-disabled');
        }
      }

      if (!isExpanded) {
        target.setAttribute('aria-expanded', 'true');
        document.getElementById(target.getAttribute('aria-controls')).removeAttribute('hidden');

        if (!allowToggle) {
          target.setAttribute('aria-disabled', 'true');
        }
      }
      else if (allowToggle && isExpanded) {
        target.setAttribute('aria-expanded', 'false');
        document.getElementById(target.getAttribute('aria-controls')).setAttribute('hidden', '');
      }

      event.preventDefault();
    } else if ((target.tagName === 'A' || target.tagName === 'SPAN') && target.parentElement.querySelector(`.${triggerClassName}`)) {
      target.parentElement.querySelector(`.${triggerClassName}`).click();
      event.preventDefault();
    }  else if (target.tagName === 'BUTTON' && target.classList.contains(showMoreButtonClass)) {
      listContainer.classList.toggle('education-page__opportunity-list--show-all');
      target.classList.toggle(showMoreButtonClass + '--active');
    }
  };

  const keyEvent = function(event) {
    const target = event.target;
    const key = event.which.toString();
    const ctrlModifier = (event.ctrlKey && key.match(/33|34/));

    if (target.classList.contains(triggerClassName)) {
      // 38 = Up, 40 = Down
      if (key.match(/38|40/) || ctrlModifier) {
        const index = triggers.indexOf(target);
        const direction = (key.match(/34|40/)) ? 1 : -1;
        const length = triggers.length;
        const newIndex = (index + length + direction) % length;

        triggers[newIndex].focus();

        event.preventDefault();
      }
      else if (key.match(/35|36/)) {
        switch (key) {
          case '36':
            triggers[0].focus();
            break;

          case '35':
            triggers[triggers.length - 1].focus();
            break;
        }

        event.preventDefault();
      }
    }
    else if (ctrlModifier) {
      panels.forEach(function(panel, index) {
        if (panel.contains(target)) {
          triggers[index].focus();
          event.preventDefault();
        }
      });
    }
  };

  return {
    init: function() {
      moduleId = context.element.id;
      config = context.getConfig();

      //if (config === undefined || config === null) {
      //  return;
      //}

      //container = context.element;
      //containerParent = container.parentNode;
      //containerType = config.type;

      container.addEventListener('click', clickEvent);

      //container.addEventListener('keydown', keyEvent);
    },

    destroy: function() {
      config = null;
      container = null;
      backgroundImage = null;
      containerType = null;
      containerParent = null;
      containerParentClass = null;
    }
  }
});
