/*
  'The' Utilities Service!
*/
SGF.Application.addService('caddyshack', function(application) {
  'use strict';

  return {
    debounce: function(func, wait, immediate) {
      var timeout;

      return function() {
        var _this = this;
        var args = arguments;
        var callNow = immediate && !timeout;
        var later = function() {
          timeout = null;

          if (!immediate) {
            func.apply(_this, args);
          }
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) {
          func.apply(_this, args);
        }
      }
    },

    preventDefault: function(event) {
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
        event.cancelBubble = true;
      }
    },

    ConvertJSONToUriArray: function(obj) {
      return Object.keys(obj).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
      });
    }
  };
});
