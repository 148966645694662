SGF.Application.addModule('page-overlay', function(context) {
  var moduleContainer;
  var body;
  var isActive;
  var config;
  var overflowClass;

  return {
    messages: ['navigation-toggle'],
    init: function() {
      moduleContainer = context.element;
      body = document.getElementsByTagName('body')[0];

      config = context.getConfig();
      overflowClass = config.overflowClass;
      isActive = config.active === 'true';

      if (isActive) {
        moduleContainer.classList.toggle('page-overlay--is-active');
      }
    },

    destroy: function() {
      moduleContainer = null;
    },

    onclick: function(event, element, type) {
      context.application.broadcast('navigation-close');
    },

    onmessage: function(name, data) {
      if (name === 'navigation-toggle') {
        if (data === undefined || data === null) {
          return;
        }

        isActive = (data.state === 'open');
        moduleContainer.classList.toggle('page-overlay--is-active');
        body.classList.toggle(overflowClass);
      }
    }
  };
});
