SGF.Application.addService('youtube-iframe-api', function(application) {
  'use strict';

  var initialized;

  function load() {
    var tag;
    var scriptTags = Array.prototype.slice.call(document.getElementsByTagName('script'));
    var existingScriptTag = scriptTags.filter(function(s) {
      return s.src === 'https://www.youtube.com/iframe_api';
    });

    if (existingScriptTag.length === 0) {
      tag = document.createElement('script');

      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      initialized = true;
    }
  }

  return {
    load: function() {
      if (initialized) {
        return;
      }

      load();
    }
  };
});
