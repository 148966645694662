var Lts = {};
Lts.dropDownFilter = (function() {
  var init = (function(formId, wrapper) {
    $(document).on('change', formId + ' select', function() {
      this.blur();
      doAjax($(formId), wrapper);
    });
  });

  function doAjax(form, wrapper) {
    var q = $('.testClass').val();
    $('.hiddenSearch').val(q);
    var f = $('.activeFilter').attr('name');
    $('.hiddenFilter').val(f);
    var url = window.location.href;
    var baseUrl = url.substring(0, url.indexOf('?')).length > 0 ? url.substring(0, url.indexOf('?')) : url;
    var pageUrl = baseUrl + '?' + form.serialize();

    //to change the browser URL to 'pageurl'
    if (pageUrl != window.location) {
      window.history.pushState({ path: pageUrl }, '', pageUrl);
    }

    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      success: function(data) {
        var dataArr = data.split('*');
        $('.filterClub').html(dataArr[1]);
        $('.filterCourse').html(dataArr[2]);
        $('.filterPlay').html(dataArr[3]);
        $(wrapper).html(data);
      }
    });
  }

  return {
    init: init
  };
}());
