/**
 * Module for framework header
 * @overview Framework header
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('framework-header', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;
  var targets = {};
  var currentScrollPosition = 0;
  var hasExpandedNavigation = false;
  var hasExpandedSearch = false;
  var application = undefined;
  var _this;

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  NodeList.prototype.first = function() {
    return this[0];
  }

  var events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;
      moduleId = context.element.id;
      application = context.application;

      targets.navigation = {};
      targets.navigation.navigationList = document.querySelectorAll('.navigation').first();
      targets.navigation.trigger = moduleContainer.querySelectorAll('.tool--navigation-toggle__toggle-link').first();

      if (targets.navigation.trigger !== undefined && targets.navigation.navigationList) {
        targets.navigation.trigger.setAttribute('aria-controls', targets.navigation.navigationList.id);
        targets.navigation.trigger.setAttribute('aria-expanded', 'false');
      }

      targets.search = {};
      targets.search.form = document.querySelectorAll('.header__search').first();
      targets.search.trigger = moduleContainer.querySelectorAll('.tool--search__toggle-link').first();

      if (targets.search.trigger !== undefined && targets.search.form) {
        targets.search.trigger.setAttribute('aria-controls', targets.search.form.id);
        targets.search.trigger.setAttribute('aria-expanded', 'false');
      }

    },

    _destroy: function() {
      moduleId = null;
      moduleContainer = null;
      targets = {};
    },

    _onclick: function(event, element, elementType) {

      if (elementType === 'navigation-toggle') {
        event.preventDefault();
        targets.navigation.trigger = element;
        events.navigation.toggle();
      }

      if (elementType === 'search-toggle') {
        targets.search.trigger = element;
        events.search.toggle();
        event.preventDefault();
      }

    },

    _onmessage: function(name, data) {
      if (name === 'windowscroll') {
        var ignoreScroll = moduleContainer.hasAttribute('ignore-scroll');

        if (hasExpandedSearch || hasExpandedNavigation || ignoreScroll) {
          return;
        }

        var offsetY = window.pageYOffset;
        var diff = currentScrollPosition - offsetY;
        var scrollBreak = moduleContainer.offsetHeight;

        if (diff > 0) {
          moduleContainer.classList.remove('framework-header--is-hidden');
          application.broadcast('framework-header', {
            state: 'visible'
          });
        }

        if (diff < 0 && offsetY > scrollBreak) {
          moduleContainer.classList.add('framework-header--is-hidden');
          application.broadcast('framework-header', {
            state: 'hidden'
          });
        }

        currentScrollPosition = offsetY;
      }

      if (name === 'navigation-close') {
        events.navigation.close();
      }
    },

    navigation: {
      toggle: function() {
        moduleContainer.classList.toggle('framework-header--navigation-visible');
        targets.navigation.trigger.classList.toggle('tool--navigation-toggle__toggle-link--is-active');
        if (targets.navigation.trigger.getAttribute('aria-expanded') === 'false') {
          targets.navigation.trigger.setAttribute('aria-expanded', 'true');
          hasExpandedNavigation = true;
        } else {
          targets.navigation.trigger.setAttribute('aria-expanded', 'false');
          hasExpandedNavigation = false;
        }

        application.broadcast('navigation-toggle', {
          state: (hasExpandedNavigation ? 'open' : 'closed')
        });
      },

      close: function() {
        moduleContainer.classList.remove('framework-header--navigation-visible');
        targets.navigation.trigger.classList.remove('tool--navigation-toggle__toggle-link--is-active');
        targets.navigation.trigger.setAttribute('aria-expanded', 'false');

        hasExpandedNavigation = false;

        application.broadcast('navigation-toggle', {
          state: 'closed'
        });
      }
    },

    search: {
      toggle: function() {
        targets.search.form.classList.toggle('header__search--is-visible');
        targets.search.trigger.classList.toggle('tool--search__toggle-link--is-active');
        if (targets.search.trigger.getAttribute('aria-expanded') === 'false') {
          targets.search.trigger.setAttribute('aria-expanded', 'true');
          hasExpandedSearch = true;
        } else {
          targets.search.trigger.setAttribute('aria-expanded', 'false');
          hasExpandedSearch = false;
        }

        application.broadcast('search-toggle', {
          state: (hasExpandedSearch ? 'open' : 'closed')
        });
      }
    }
  }
  return {
    behaviors: ['window-events'],
    messages: ['windowscroll', 'navigation-close'],
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick,
    onmessage: events._onmessage
  };
});
