class AllPlaylistItems extends React.Component {

  constructor(props) {
    super(props)
    this.openVideoPlayer = this.openVideoPlayer.bind(this);
    this.state = {
      playlistContent: [],
      viewCount: 9
    }
  }

  openVideoPlayer(obj) {
    this.props.openVideoPlayer(obj)
  }

  componentDidMount() {
    this.updatePlaylistState(this.props.playlists)
  }

  componentWillReceiveProps(nextProps) {
    this.updatePlaylistState(nextProps.playlists)
  }

  updatePlaylistState(list) {
    if(list && list.items) {
      list.items.map(item => {
        const playlistContent = VideoPlayerApi.channelPlaylistContent(item.id);
        playlistContent.then(value => {
          let newPlaylistContent = [...this.state.playlistContent]
          value.items.map(valueItem => {
            if (newPlaylistContent.filter(e => e.contentDetails.videoId === valueItem.contentDetails.videoId).length === 0) {
              newPlaylistContent.push(valueItem);
            }
          })
          this.setState({ playlistContent:  newPlaylistContent})
        })
      })
    }
  }

  setViewCountState() {
    this.setState({
      viewCount: this.state.viewCount + 9
    })
  }

  render() {   
    let sortedItems = this.state.playlistContent.sort((a, b) => (new Date(a.contentDetails.videoPublishedAt) < new Date(b.contentDetails.videoPublishedAt)) ? 1 : -1);

    return (
      <main className="golf-play-main">
        <div>
          <p>Senaste videor</p>
          <ul>
            {sortedItems ? sortedItems.slice(0, this.state.viewCount).map(item =>
              <PlaylistItem
                key={item.id}
                videoObject={item}
                selectedPlaylist={item.snippet.playlistId}
                openVideoPlayer={this.openVideoPlayer}
                selectedPlaylistObject={content}
                currentVideoId={this.props.currentVideoId} />
            ) : ''}
          </ul>
          {(sortedItems && sortedItems.length <= this.state.viewCount) ? '' : 
            <div className="see-more"><div className="see-more-button" onClick={() => this.setViewCountState()}>
              <span>Visa fler</span>
            </div>
          </div>}
        </div>
      </main>
    )
  }
}
