var VideoPlayerApi = (function() {
  var apiKey = 'AIzaSyAci99hbjyN7s_ytkUs5TbZiIihAG8lgqw'; //https://console.developers.google.com
  var maxResults = 100;

  var getChannelPlaylists = function() {
    var channelID = 'UC_DDvhvP90lhIAsP23wGGyQ';
    var url = 'https://www.googleapis.com/youtube/v3/playlists?key=' + apiKey + '&part=snippet&maxResults=' + (maxResults > 50 ? 50 : maxResults) + '&channelId=' + channelID;
    var apiResult = callApi({
      url: url,
      name: 'sgf_yt_channel_' + channelID,
      currentContent: window.localStorage.getItem('sgf_yt_channel_' + channelID)
    });

    return new Promise(function(resolve, reject) {
      resolve(apiResult);
    });
  }

  var getChannelPlaylistContent = function(playlistId) {
    var url = 'https://www.googleapis.com/youtube/v3/playlistItems?key=' + apiKey + '&part=snippet,contentDetails&maxResults=' + (maxResults > 50 ? 50 : maxResults) + '&playlistId=' + playlistId;
    var apiResult = callApi({
      url: url,
      name: 'sgf_yt_playlist_' + playlistId,
      currentContent: window.localStorage.getItem('sgf_yt_playlist_' + playlistId)
    });

    return new Promise(function(resolve, reject) {
      resolve(apiResult);
    });
  }

  var callApi = function(context) {
    return new Promise(function(resolve, reject) {
      if (context.currentContent) {
        context.currentContent = JSON.parse(context.currentContent);
      }

      if (context.currentContent && context.currentContent.date && new Date(context.currentContent.date) < new Date()) {
        context.currentContent = null;
      }

      if (!context.currentContent) {
        var xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function() {
          if (this.readyState === 4 && this.status === 200) {
            context.currentContent = {
              date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
              response: JSON.parse(this.responseText)
            };

            window.localStorage.setItem(context.name, JSON.stringify(context.currentContent));

            resolve(context.currentContent.response);
          }
        };

        xmlhttp.onerror = function() {
          reject(this.statusText);
        };

        xmlhttp.open('GET', context.url, true);
        xmlhttp.send();
      }
      else {
        resolve(context.currentContent.response);
      }
    });
  }

  return {
    channelPlaylistContent: getChannelPlaylistContent,
    channelPlaylists: getChannelPlaylists
  };
})();
