/**
 * Masonry Rss Block
 * @overview For a masonry rss grid item
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-rss-block', function(context) {
  'use strict';

  var moduleId;
  var messageName = 'masonry-grid-item-loaded';

  return {
    init: function() {
      moduleId = context.element.id;
      context.application.broadcast(messageName, { id: moduleId });
    },

    destroy: function() {
      moduleId = null;
    }
  }
});
