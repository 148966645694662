class SinglePlaylist extends React.Component {

    constructor(props) {
        super(props)
        this.openVideoPlayer = this.openVideoPlayer.bind(this);
        this.state = {
            playlistContent: {},
            playlistIsOpen: false
        }
    }

    componentDidMount() {
        if (this.props.selectedPlaylist !== '') {
            var playlistContent = VideoPlayerApi.channelPlaylistContent(this.props.selectedPlaylist);
            playlistContent.then(value => {
                this.setState({ playlistContent: value })
            })
        }
    }
    
    openVideoPlayer(obj) {
        this.props.openVideoPlayer(obj)
    }

    setPlaylistOpenState() {
        this.setState({
            playlistIsOpen: !this.state.playlistIsOpen
        })
    }

    render() {
        const content = this.state.playlistContent
        const maxItemCount = this.props.appWidth <= 820 ? 2 : 3
        let itemSize = (this.props.showAll || this.state.playlistIsOpen) ? 1000 : maxItemCount
        const playlistOpenClassName = this.state.playlistIsOpen ? 'see-more-button see-more-button--open' : 'see-more-button'

        return (
            <div>
                <p>{this.props.playlistName}</p>
                <ul>
                    {content.items ? content.items.slice(0, itemSize).map(item =>
                        <PlaylistItem
                            key={item.id}
                            videoObject={item}
                            openVideoPlayer={this.openVideoPlayer}
                            selectedPlaylist={this.props.selectedPlaylist}
                            selectedPlaylistObject={content}
                            currentVideoId={this.props.currentVideoId} />
                    ) : ''}
                </ul>
                {(this.props.showAll || (content.items && content.items.length <= 3)) ? '' : <div className="see-more"><div className={playlistOpenClassName} onClick={() => this.setPlaylistOpenState()}>
                    {this.state.playlistIsOpen ?
                        <span>Visa f&auml;rre</span> :
                        <span>Visa fler</span>}
                </div></div>}
            </div>
        )
    }
}
