/**
 * Module for framework navigation
 * @overview Framework navigation
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('framework-navigation', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;
  var targets = {};
  var isExpanded = false;
  var _this;

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  var events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;
      moduleId = context.element.id;

      moduleContainer.classList.remove('navigation--no-js');

      moduleContainer.setAttribute('aria-expanded', isExpanded);

      targets.triggers = moduleContainer.querySelectorAll('.navigation-list__list-item__link--trigger').toArray();
      targets.children = moduleContainer.querySelectorAll('[class$=list__list-item]').toArray();

      targets.triggers.forEach(function(trigger) {
        if (trigger.getAttribute('aria-expanded') === null || trigger.getAttribute('aria-expanded') === undefined) {
          trigger.setAttribute('aria-expanded', 'false');
        }
      });
    },

    _destroy: function() {
      moduleId = null;
      moduleContainer = null;
      targets = {};
    },

    _onclick: function(event, element, elementType) {

      if (elementType === 'navigation-item-toggle') {
        var targetElementId = element.getAttribute('aria-controls');
        var targetElement = moduleContainer.querySelectorAll('#' + targetElementId).toArray()[0];

        targets.children.forEach(function(child) {
          if (child.id === targetElementId) {
            if (child.className.indexOf('sub-navigation') > -1) {
              child.classList.toggle('navigation-list__sub-navigation-list__list-item--expanded');
            } else {
              child.classList.toggle('navigation-list__list-item--expanded');
            }
          }
        });

        if (element.getAttribute('aria-expanded') === 'true' && !targetElement.classList.contains('navigation-list__list-item--expanded') && !targetElement.classList.contains('navigation-list__sub-navigation-list__list-item--expanded')) {
          element.title = element.getAttribute('data-labelexpand');
          element.setAttribute('aria-expanded', 'false');
        } else {
          element.title = element.getAttribute('data-labelclose');
          element.setAttribute('aria-expanded', 'true');
        }

        event.preventDefault();
      }
    },

    _onmessage: function(name, data) {
      if (name === 'navigation-toggle') {
        moduleContainer.classList.toggle('navigation--is-visible');
        isExpanded = !isExpanded;
        moduleContainer.setAttribute('aria-expanded', isExpanded);
      }

      if (name === 'navigation-close') {
        moduleContainer.classList.remove('navigation--is-visible');
        isExpanded = false;
        moduleContainer.setAttribute('aria-expanded', isExpanded);
      }
    }
  }
  return {
    messages: ['navigation-toggle', 'navigation-close'],
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick,
    onmessage: events._onmessage
  };
});
