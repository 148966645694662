class VideoPlayer extends React.Component {

    constructor(props) {
        super(props)
        this.parentOpenVideoPlayer = this.parentOpenVideoPlayer.bind(this);
        this.parentCloseVideoPlayer = this.parentCloseVideoPlayer.bind(this);
    }

    parentCloseVideoPlayer() {
        this.props.closeVideoPlayer()
    }

    parentOpenVideoPlayer(obj) {
        this.props.openVideoPlayer(obj)
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.closeVideoPlayer()
        }
    }

    componentDidMount() {
        document.body.classList.add('no-scroll')
        document.querySelector('.framework-header').classList.add('framework-header--is-hidden')

        document.addEventListener("keydown", this.escFunction.bind(this), false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction.bind(this), false);
    }

    componentWillUnmount() {
        document.body.classList.remove('no-scroll')
        document.querySelector('.framework-header').classList.remove('framework-header--is-hidden')
    }

    render() {
        var videoId = this.props.videoobject.contentDetails.videoId
        var videoUrl = 'https://www.youtube.com/embed/' + videoId + '?showinfo=0&amp;autoplay=1&amp;rel=0'
        var videoTitle = this.props.videoobject.snippet.title
        var videoDescription = this.props.videoobject.snippet.description
        var currentPlaylistId = this.props.videoobject.currentPlaylistId

        return (
            <div>
                <div className="golf-play-player-bg" onClick={() => this.parentCloseVideoPlayer()}>
                </div>
                <div className="golf-play-player">
                    <iframe src={videoUrl} className="clip-video" frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
                    <div className="clip-related">
                        <SinglePlaylist
                            key={'related-' + currentPlaylistId}
                            selectedPlaylist={currentPlaylistId}
                            playlistName='Relaterade klipp'
                            openVideoPlayer={this.parentOpenVideoPlayer}
                            currentVideoId={videoId}
                            showAll={true} />
                    </div>
                    <div className="clip-infowrapper">
                        <h4 className="clip-infowrapper-title">{videoTitle}</h4>
                        <div className="clip-infowrapper-description">{videoDescription}</div>
                    </div>
                    <span className="golf-play-player-close" onClick={() => this.parentCloseVideoPlayer()}>STÄNG</span>
                </div>
            </div>
        )
    }
}