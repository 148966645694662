/**
 * Tabs
 * @overview For tab of items
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('full-grid-tabs', function(context) {
  var _this;
  var moduleContainer;
  var tabs;
  var tabPanels;

  var options = {
    tabs: {
      query: '.tab-list-item__tab-link',
      selectedClass: 'tab-list-item__tab-link--selected',
      elementType: 'tab'
    },

    panels: {
      query: '.full-grid-tabs__tab-panel',
      selectedClass: 'full-grid-tabs__tab-panel--selected',
      hiddenClass: 'full-grid-tabs__tab-panel--hidden'
    }
  };

  function setupVisibility() {
    var activeTab = tabs[0];
    var activePanel = tabPanels[0];
    var labeledBy;
    var parentNode;

    if (window.location.hash) {
      var _hash = window.location.hash;
      var itemQuery = moduleContainer.querySelectorAll(_hash);

      if (itemQuery.length > 0) {
        if (itemQuery[0].classList.contains('full-grid-tabs__tab-list-item')) {
          activePanel = itemQuery[0];
        } else {
          if (itemQuery[0].classList.contains('full-grid-tabs__tab-panel')) {
            parentNode = itemQuery[0]
          } else {
            parentNode = itemQuery[0].parentNode;

            while (parentNode && !parentNode.classList.contains('full-grid-tabs__tab-panel')) {
              parentNode = parentNode.parentNode;
            }
          }

          activePanel = parentNode;
        }

        if (activePanel) {
          labeledBy = activePanel.getAttribute('aria-labeledby');

          tabs.forEach(function(item) {
            if (item.id === labeledBy) {
              activeTab = item;
            }
          });
        }
      }
    }

    hideElements();
    showElements({
      tab: activeTab,
      panel: activePanel
    });
  }

  function displayContent(args) {
    var el = args.element;
    var targetPanelQuery = el.hash;
    var targetPanel = moduleContainer.querySelector(targetPanelQuery);

    hideElements();
    showElements({
      tab: el,
      panel: targetPanel
    });
  }

  function hideElements() {
    tabs.forEach(function(tab) {
      tab.setAttribute('aria-selected', 'false');
      tab.classList.remove(options.tabs.selectedClass);
    });

    tabPanels.forEach(function(panel) {
      panel.setAttribute('aria-hidden', 'true');
      panel.classList.add(options.panels.hiddenClass);
      panel.classList.remove(options.panels.selectedClass);
    });
  }

  function showElements(args) {
    var targetTag = args.tab;
    var targetPanel = args.panel;

    targetTag.setAttribute('aria-selected', 'true');
    targetTag.classList.add(options.tabs.selectedClass);

    targetPanel.removeAttribute('aria-hidden');
    targetPanel.classList.add(options.panels.selectedClass);
    targetPanel.classList.remove(options.panels.hiddenClass);
  }

  var events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;

      var nodeListTabs = moduleContainer.querySelectorAll(options.tabs.query);
      tabs = Array.prototype.slice.call(nodeListTabs);

      var nodeListTabPanels = moduleContainer.querySelectorAll(options.panels.query);
      tabPanels = Array.prototype.slice.call(nodeListTabPanels);

      setupVisibility();
    },

    _destroy: function() {
      _this = null;
      moduleContainer = null;
      tabs = null;
      tabPanels = null;
    },

    _onclick: function(event, element, elementType) {
      if (elementType === options.tabs.elementType) {
        if (!window.history || !window.history.pushState) {
          if (event.preventDefault) {
            event.preventDefault();
          } else {
            event.returnValue = false;
          }

          displayContent({
            event: event,
            element: element
          });

        } else {
          window.history.pushState(null, null, element.href);
          event.preventDefault();

          displayContent({
            event: event,
            element: element
          });
        }

        context.application.broadcast('full-grid-tab-click');
      }
    }
  }

  return {
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick
  };
});
