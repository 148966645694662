/**
 * Avalanchemessage
 * @overview Avalanchemessage
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('avalanche-message', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;

  var events = {
    _init: function() {

      moduleContainer = context.element;
      moduleId = context.element.id;
    },

    _destroy: function() {
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {
      if (elementType === 'close-avalanche-message') {
        moduleContainer.classList.add('avalanche-message-block--hidden');
      }
    }
  }
  return {
    init: events._init,
    destroy: events._destroy,
    onclick: events._onclick
  };
});
