/**
 * Feature
 * @overview For feature block
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('feature-block', function(context) {
  var moduleContainer;
  var wrapperElement;
  var contentElement;

  var resize = function() {
    var w = window;
    var d = document;
    var e = d.documentElement;
    var g = d.getElementsByTagName('body')[0];
    var x = w.innerWidth || e.clientWidth || g.clientWidth;
    var y = w.innerHeight || e.clientHeight || g.clientHeight;
    var fontSize = 18;
    var ratio = 0.8;

    if (x <= 820) {
      wrapperElement.style.fontSize = fontSize + 'px';

      while (fontSize-- > 1 && ((contentElement.clientWidth > (wrapperElement.clientWidth * ratio)) || (contentElement.clientHeight > (wrapperElement.clientHeight * ratio)))) {
        wrapperElement.style.fontSize = fontSize + 'px';
      }

      if (wrapperElement.classList.contains('feature-block--quote') && fontSize > 13) {
        wrapperElement.style.fontSize = 13 + 'px';
      }
    } else {
      wrapperElement.style.fontSize = '';
    }
  }

  var events = {
    _init: function() {
      moduleContainer = context.element;
      wrapperElement = moduleContainer.querySelector('.feature-block__wrapper');

      if (!wrapperElement) {
        wrapperElement = moduleContainer;
      }

      contentElement = moduleContainer.querySelector('.feature-block__heading');

      if (!contentElement) {
        contentElement = moduleContainer.querySelector('.feature-block__wrapper__heading');
      }

      if (contentElement) {
        resize();
      }
    },

    _destroy: function() {
      moduleContainer = null;
      wrapperElement = null;
      contentElement = null;
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'windowresize':
          if (contentElement) {
            resize();
          }

          break;
      }
    }
  };

  return {
    behaviors: ['window-events'],
    messages: ['windowresize'],
    init: events._init,
    destroy: events._destroy,
    onmessage: events._onmessage
  };
});
