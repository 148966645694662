/**
 * Module for More golf, full screen block
 * @overview More golf, full screen block
 * @copyright (c) 2019
 * @author Team Juliett
 */
SGF.Application.addModule('full-screen-block', function(context) {
  'use strict';

  var moduleContainer;
  var moduleId;
  var application = undefined;
  var config;
  var _this;
  var infoWindows = [];

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  };

  NodeList.prototype.first = function() {
    return this[0];
  };

  var currentTime = 0;
  var start = 0;
  var change = 0;
  var animationDuration = 750;
  var isSafari = (navigator.userAgent.toLowerCase().indexOf('safari') > -1 && navigator.userAgent.toLowerCase().indexOf('chrome') === -1);
  var isInternetExplorer = (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)));
  var isFirefox = (window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf('Firefox') > 0);

  var animateScroll = function(targetElement) {
    if (targetElement) {
      currentTime = 0;
      start = window.pageYOffset || document.documentElement.scrollTop;
      change = (window.pageYOffset + targetElement.getBoundingClientRect().top) - start;
    }

    var increment = 20;

    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, animationDuration);

    if (isSafari) {
      window.scroll(0, val);
    }
    else if (document.documentElement.scrollTop !== undefined) {
      document.documentElement.scrollTop = val;
    }
    else if (window.document.body.scrollTop !== undefined && !isInternetExplorer && !isFirefox) {
      window.document.body.scrollTop = val;
    }

    if (currentTime < animationDuration) {
      setTimeout(animateScroll, increment);
    }
  };

  var easeInOutQuad = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  var addClickEvents = function() {
    var linkMoveToText = moduleContainer.querySelector('.full-screen-block__scroll-down');
    if (!linkMoveToText) return

    linkMoveToText.addEventListener('click', function(event) {
      animateScroll(moduleContainer.nextElementSibling);
      event.preventDefault();
    }, false);
  };

  var events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;
      moduleId = context.element.id;
      application = context.application;
      config = context.getConfig();

      addClickEvents();
    },

    _destroy: function() {
      moduleId = null;
      moduleContainer = null;
    }
  };

  return {
    behaviors: ['window-events'],
    init: events._init,
    destroy: events._destroy
  };
});
