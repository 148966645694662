/**
 * Carousel for full grid use.
 * @overview For rotation of added objects
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('full-grid-carousel', function(context) {
  var moduleContainer;
  var carouselContainer;
  var carouselContainerElements;
  var carouselIndicatorElements;
  var carouselCurrentIndicatorIndex = 0;
  var indicatorSelectClass = 'full-grid-carousel__carousel-navigation__navigation-item--selected';
  var currentPosition = 0;
  var moduleId;
  var touchStart;
  var touchEnd;
  var _this;

  const rotatationDirections = {
    DIRECTION_LEFT: 0,
    DIRECTION_RIGHT: 1
  };

  var calculateWidth = function() {

    var originalWidth = moduleContainer.offsetWidth;
    carouselContainer.style.width = (originalWidth * carouselContainerElements.length) + 'px';
    carouselContainer.style.left = ((originalWidth * currentPosition) * -1) + 'px';

    carouselContainerElements.forEach(function(el) {
      el.style.width = originalWidth + 'px';
    });

    carouselContainer.classList.add('full-grid-carousel__carousel-list--resizeable');
  }

  var addControls = function() {
    var previousButton = createControl({
      clientId: 'button-previous',
      label: 'Previous',
      dataType: 'button-previous',
      role: 'button',
      classlist: ['full-grid-carousel__navigation-item', 'full-grid-carousel__navigation-item--previous']
    });

    var previousButtonId = previousButton.id;
    moduleContainer.appendChild(previousButton);

    var nextButton = createControl({
      clientId: 'button-next',
      label: 'Next',
      dataType: 'button-next',
      role: 'button',
      classlist: ['full-grid-carousel__navigation-item', 'full-grid-carousel__navigation-item--next']
    });

    var nextButtonId = nextButton.id;
    moduleContainer.appendChild(nextButton);

    moduleContainer.setAttribute('aria-controls', [nextButtonId, previousButtonId]);
  }

  var createControl = function(args) {
    var control = document.createElement('a');
    var controlId = moduleId + '_' + args.clientId;
    var controlText = document.createTextNode(args.label);

    if (control.dataset !== undefined) {
      control.dataset.type = args.dataType;
    } else {
      control.setAttribute('data-type', args.dataType);
    }

    control.setAttribute('role', args.role);
    control.setAttribute('id', controlId);
    control.setAttribute('aria-controls', moduleId);

    if (args.classlist) {
      args.classlist.forEach(function(item) {
        control.classList.add(item);
      });
    }

    control.appendChild(controlText);

    return control;
  }

  var resize = function() {
    carouselContainer.classList.remove('full-grid-carousel__carousel-list--resizeable');
    return calculateWidth();
  }

  var rotate = function(direction) {
    var nextPosition;

    switch (direction) {
    case rotatationDirections.DIRECTION_LEFT:
      if (currentPosition === 0) {
        nextPosition = (carouselContainerElements.length - 1);
      } else {
        nextPosition = (currentPosition - 1);
      }

      break;

    case rotatationDirections.DIRECTION_RIGHT:
      if (currentPosition === (carouselContainerElements.length - 1)) {
        nextPosition = 0;
      } else {
        nextPosition = (currentPosition + 1);
      }

      break;
    }
    currentPosition = nextPosition;
    updatePosition(nextPosition);
  }

  var updatePosition = function(index) {
    carouselCurrentIndicatorIndex = index;
    carouselContainer.style.left = ((moduleContainer.offsetWidth * index) * -1) + 'px';
    updateIndicator();
    context.broadcast('full-grid-carousel.update-position', { position: index });
  }

  var updateIndicator = function() {
    if (carouselIndicatorElements !== undefined) {
      carouselIndicatorElements.forEach(function(item) {
        item.classList.remove(indicatorSelectClass);
      });

      carouselIndicatorElements[carouselCurrentIndicatorIndex].classList.add(indicatorSelectClass);
    }

  }

  var updateByIndicator = function(el) {
    if (el.dataset.target !== undefined) {
      currentPosition = el.dataset.target;
      updatePosition(el.dataset.target);
    }
  }

  var events = {
    _init: function() {
      _this = this;

      moduleContainer = context.element;
      moduleId = context.element.id;

      carouselContainer = moduleContainer.querySelectorAll('.full-grid-carousel__carousel-list')[0];

      var nodelistCarouselContainerElements = moduleContainer.querySelectorAll('.full-grid-carousel__carousel-list-item');
      carouselContainerElements = Array.prototype.slice.call(nodelistCarouselContainerElements);

      var indicatorWrapper = moduleContainer.querySelectorAll('.full-grid-carousel__carousel-navigation')[0];

      if (indicatorWrapper !== undefined && indicatorWrapper !== null) {
        indicatorWrapper.setAttribute('aria-controls', moduleId);

        var nodelistCarouselIndicatorElements = moduleContainer.querySelectorAll('.full-grid-carousel__carousel-navigation__navigation-item');
        carouselIndicatorElements = Array.prototype.slice.call(nodelistCarouselIndicatorElements);
        carouselIndicatorElements[0].classList.add(indicatorSelectClass);
      }

      if (carouselContainerElements.length > 1) {
        addControls();
      }

      carouselContainer.addEventListener('touchend', _this.ontouchend, false);
      carouselContainer.addEventListener('touchstart', _this.ontouchstart, false);

      return calculateWidth();
    },

    _destroy: function() {
      carouselContainer.removeEventListener('touchend');
      carouselContainer.removeEventListener('touchstart');

      moduleContainer = null;
      carouselContainer = null;
      carouselContainerElements = null;
    },

    _onclick: function(event, element, elementType) {
      switch (elementType) {
      case 'button-previous':
        rotate(rotatationDirections.DIRECTION_LEFT);
        break;
      case 'button-next':
        rotate(rotatationDirections.DIRECTION_RIGHT);
        break;
      case 'indicator':
        event.preventDefault();
        updateByIndicator(element);
        break;
      }
    },

    _onmessage: function(name, data) {
      switch (name) {
      case 'windowresize':
        resize();
        break;
      case 'full-grid-tab-click':
        this.init()
        break;
      }
    },

    _ontouchend: function(event) {
      touchEnd = event.changedTouches[0].clientX;

      if (touchEnd > touchStart) {
        rotate(rotatationDirections.DIRECTION_LEFT);
      }

      if (touchStart > touchEnd) {
        rotate(rotatationDirections.DIRECTION_RIGHT);
      }

      touchEnd = 0;
      touchStart = 0;
    },

    _ontouchstart: function(event) {
      touchStart = event.changedTouches[0].clientX;
    }
  }

  return {
    behaviors: ['window-events'],
    messages: ['windowresize', 'full-grid-tab-click'],
    init: events._init,
    destroy: events._destroy,
    onmessage: events._onmessage,
    onclick: events._onclick,
    ontouchend: events._ontouchend,
    ontouchstart: events._ontouchstart
  };
});
