Box.Application.addModule('spinner', function(context) {
  'use strict';

  var initialized;
  var moduleId;
  var wrapper;
  var spinner;
  var circles;
  var radius;
  var scaling;
  var requestAnimationFrame;
  var raf = 0;
  var animationPosition = 0;
  var animationStartRadiuses = [];
  var running;
  var config = {
    numberOfElements: 8,
    color: '#ffffff',
    circleSize: 0.15,
    initialScaling: [0.0, 0.0, 0.0, 0.5, 1.0, 1.0, 0.75, 0.2]
  };

  var setup = function() {
    var width = context.element.offsetWidth;
    var height = context.element.offsetHeight;
    var x;
    var y;
    var distance;
    var i;
    var circle;

    radius = Math.round((Math.floor(config.circleSize * width)) / 2);
    x = Math.floor(Math.min(width, height) / 2 - radius);
    y = x;
    distance = x;

    spinner = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    spinner.id = moduleId + '-spinner';
    spinner.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    spinner.setAttribute('version', '1.1');
    spinner.setAttribute('width', '100%');
    spinner.setAttribute('height', '100%');
    spinner.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
    spinner.style.left = '0px';
    spinner.style.top = '0px';

    circles = [];
    scaling = config.initialScaling.slice();
    scaling = scaling.concat(scaling.slice());

    for (i = 1; i <= config.numberOfElements; i++) {
      circle = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
      circle.id = moduleId + '-spinner-ellipse-' + i;
      circle.setAttribute('cx', Math.round(x + distance * Math.cos(2 * i * Math.PI / config.numberOfElements) + radius));
      circle.setAttribute('cy', Math.round(y + distance * Math.sin(2 * i * Math.PI / config.numberOfElements) + radius));
      circle.setAttribute('rx', radius * scaling[i - 1]);
      circle.setAttribute('ry', radius * scaling[i - 1]);
      circle.setAttribute('fill', config.color);
      spinner.appendChild(circle);
      circles.push(circle);
      animationStartRadiuses[i - 1] = radius * scaling[i - 1];
    }

    wrapper.appendChild(spinner);

    hide();
  }

  var animate = function() {
    var i;
    var circleRadius;
    var rafMultiplier;

    if (!running) {
      return;
    }

    raf = raf + 1;

    if (raf === 8) {
      raf = 0;

      for (i = 0; i < config.numberOfElements; i++) {
        animationStartRadiuses[i] = parseFloat(circles[i].getAttribute('rx'));
      }

      animationPosition--;

      if (animationPosition < 0) {
        animationPosition = scaling.length - config.numberOfElements - 1;
      }
    }

    rafMultiplier = (((raf + 1) / 10) * 1.25);

    for (i = 0; i < config.numberOfElements; i++) {
      circleRadius = (animationStartRadiuses[i] + (scaling[i + animationPosition] * radius - animationStartRadiuses[i]) * rafMultiplier);
      circles[i].setAttribute('rx', circleRadius);
      circles[i].setAttribute('ry', circleRadius);
    }

    requestAnimationFrame(animate);
  }

  var start = function() {
    if (!running) {
      wrapper.style.display = 'block';
      running = true;
      animate();
    }
  }

  var stop = function() {
    if (running) {
      hide();
    }
  }

  var hide = function() {
    wrapper.style.display = 'none';
    running = false;
  }

  return {
    behaviors: ['request-animation-frame'],

    messages: ['request-animation-frame.initialized', 'spinner.start', 'spinner.stop'],

    init: function() {
      moduleId = context.element.id;
      wrapper = document.createElement('div');
      wrapper.id = moduleId + '-wrapper';
      context.getElement().appendChild(wrapper);
      requestAnimationFrame = context.getGlobal('requestAnimationFrame');
      if (requestAnimationFrame) {
        initialized = true;
        setup();
      }
    },

    onmessage: function(name, data) {
      if (name === 'request-animation-frame.initialized') {
        if (!initialized) {
          initialized = true;
          requestAnimationFrame = context.getGlobal('requestAnimationFrame');
          setup();
        }
      } else if (name === 'spinner.start') {
        if (initialized && data.id === moduleId) {
          start();
        }
      } else if (name === 'spinner.stop') {
        if (initialized && data.id === moduleId) {
          stop();
        }
      }
    },

    start: start,

    stop: stop,

    onclick: function() {
      if (running) {
        stop();
      } else {
        start();
      }
    }
  }
});
