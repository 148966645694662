//http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
SGF.Application.addBehavior('request-animation-frame', function(application) {
  'use strict';

  var initialized;

  var init = function() {
    var lastTime = 0;
    var vendors = ['webkit', 'moz'];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
      window.cancelAnimationFrame =
        window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function(callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));

        //jscs:disable
        var id = window.setTimeout(function() { callback(currTime + timeToCall); }, timeToCall);
        //jscs:enable

        lastTime = currTime + timeToCall;
        return id;
      };
    }

    if (!window.cancelAnimationFrame) {
      window.cancelAnimationFrame = function(id) {
        clearTimeout(id);
      };
    }
  }

  return {
    init: function() {
      if (!initialized) {
        initialized = true;
        init();
        application.broadcast('request-animation-frame.initialized');
      }
    }
  }
});
