/**
 * Main tour page
 * @overview Main tour page
 * @copyright (c) 2018
 * @author Team Juliett
 */
SGF.Application.addModule('start-playing-golf-start-page', function(context) {
  'use strict';

  let _this;
  let aniTick = false;
  let moduleContainer;
  let splashContainers = [];

  const preventDefault = function(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
      event.cancelBubble = true;
    }
  }

  const setSplashContainersSize = function() {
    if (!aniTick) {
      splashContainers = [...moduleContainer.querySelectorAll('div.splash-container')];

      window.requestAnimationFrame(function() {
        var viewportWidth = document.body.clientWidth;
        var containerMargins = Math.ceil(viewportWidth / 2);

        splashContainers.map(function(container) {
          container.style.width = viewportWidth + 'px';
          container.style.marginLeft = '-' + containerMargins + 'px';
          container.style.marginRight = '-' + containerMargins + 'px';
        });

        aniTick = false;
      });
    }

    aniTick = true;
  };

  const events = {
    _init: function() {
      _this = this;
      moduleContainer = context.element;

      setSplashContainersSize();
      window.addEventListener('resize', setSplashContainersSize);
    },

    _destroy: function() {
      moduleContainer = null;
    }
  }
  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  return {
    init: events._init
  };
});
