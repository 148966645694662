/**
 * Statistics block.
 * @overview Statistics block
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('hero-statistics-block', function(context) {
  'use strict';

  var loops = 120;
  var contextElement;
  var config;
  var number;
  var numberElement;
  var requestAnimationFrame;
  var initialized;
  var currentLoop;
  var visible;

  var isIE = function() {
    var nav = navigator.userAgent.toLowerCase();
    return (nav.indexOf('msie') !== -1) ? parseInt(nav.split('msie')[1]) : false;
  }

  var formatNumber = function(value) {
    var n;
    var n1;
    var n2;
    var r;

    if (/^\d{3,}$/.test(value)) {
      value += '';
      n = value.split('.');
      n1 = n[0];
      n2 = n.length > 1 ? ' ' + n[1] : '';
      r = /(\d+)(\d{3})/;

      while (r.test(n1)) {
        n1 = n1.replace(r, '$1' + ' ' + '$2');
      }

      return n1 + n2;
    }

    return value;
  }

  var count = function() {
    var val;

    if (++currentLoop < loops) {
      val = parseInt((number / loops) * currentLoop);
      numberElement.textContent = (val >= 1000 ? formatNumber(val) : val);
      requestAnimationFrame(count);
    } else {
      numberElement.textContent = (number >= 1000 ? formatNumber(number) : number);
    }
  }

  var checkIfVisible = function() {
    var elementRect;
    var browserHeight;

    if (currentLoop > 0 || visible) {
      return;
    }

    elementRect = contextElement.getBoundingClientRect();
    browserHeight = window.innerHeight || document.documentElement.clientHeight;

    if (!visible && elementRect.top <= (browserHeight - 260)) {
      visible = true;
      requestAnimationFrame(count);
    }
  }

  var setup = function() {
    currentLoop = 0;

    if (isIE() === 9) {
      loops = 60;
    }

    checkIfVisible();
  }

  var events = {
    _init: function() {
      requestAnimationFrame = context.getGlobal('requestAnimationFrame');
      contextElement = context.element;
      numberElement = contextElement.querySelector('.hero-statistics-block__statistics__item__number');
      config = context.getConfig();
      number = config.number;

      if (!requestAnimationFrame || number <= 0) {
        numberElement.innerText = formatNumber(number);
        return;
      }

      numberElement.innerText = 0;
      initialized = true;
      setup();
    },

    _onmessage: function(name, data) {
      if (name === 'request-animation-frame.initialized') {
        if (!initialized) {
          initialized = true;
          requestAnimationFrame = context.getGlobal('requestAnimationFrame');
          setup();
        }
      } else if (name === 'windowscroll') {
        checkIfVisible();
      } else if (name === 'windowresize') {
        checkIfVisible();
      }
    }
  }

  return {
    behaviors: ['request-animation-frame', 'window-events'],
    messages: ['request-animation-frame.initialized', 'windowscroll', 'windowresize'],
    init: events._init,
    onmessage: events._onmessage
  };
});
