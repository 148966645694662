/**
 * Masonry Grid Item
 * @overview For a masonry grid item
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-grid-item', function(context) {
  'use strict';

  var config;
  var container;
  var moduleId;
  var backgroundImage;
  var containerType;
  var messageName = 'masonry-grid-item-loaded';

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  function renderDuoTone() {
    if (backgroundImage !== undefined && backgroundImage !== null) {
      container.style.backgroundImage = 'url(' + backgroundImage + ')';
    }

    broadcast();
  }

  function renderStandard() {
    const image = container.getElementsByTagName('img')[0];

    if (image === null || image === undefined) {
      broadcast();
      return;
    }

    if (image.readyState === 'interactive' || image.readyState === 'complete') {
      broadcast();
    } else {
      image.addEventListener('load', function() {
        broadcast();
      });
    }
  }

  function renderQuotation() {
    broadcast();
  }

  return {
    init: function() {
      moduleId = context.element.id;
      config = context.getConfig();

      if (config === undefined || config === null) {
        return;
      }

      container = context.element;
      containerType = config.type;

      switch (containerType.toLowerCase()) {
        case 'duotone':
          backgroundImage = config.image;
          renderDuoTone();
          break;

        case 'quotation':
          renderQuotation();
          break;

        default:
          renderStandard();
          break;
      }
    },

    destroy: function() {
      config = null;
      container = null;
      backgroundImage = null;
      containerType = null;
    }
  }
});
