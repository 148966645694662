SGF.Application.addService('googlemaps-api', function(application) {
  'use strict';

  var initialized;
  var apiKey = 'AIzaSyAVSE3YOhQXnS9WPyCVGGCx80GSeaXXN4E';

  function load() {
    var tag;
    var tagSecond;
    var scriptTags = Array.prototype.slice.call(document.getElementsByTagName('script'));
    var existingScriptTag = scriptTags.filter(function(s) {
      return s.src === '//maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=onGoogleMapsDataApiReady';
    });

    if (existingScriptTag.length === 0) {
      var firstScriptTag = document.getElementsByTagName('script')[0];

      tag = document.createElement('script');
      tag.src = '//maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=onGoogleMapsDataApiReady';
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      initialized = true;
    }
  }

  return {
    load: function() {
      if (initialized) {
        return;
      }

      load();
    }
  };
});
