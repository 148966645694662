/**
 * Web panel block.
 * @overview Web panel block
 * @copyright (c) 2016
 * @author Team Golf
 */
SGF.Application.addModule('web-panel-block', function(context) {
  var validate = function(element, regex) {
    if ((!element.value || element.value === '') || (regex && !element.value.match(regex))) {
      element.classList.add('web-panel-block__input--error');
      return false;
    }

    element.classList.remove('web-panel-block__input--error');
    return true;
  };

  var postForm = function() {
    var xhr = new XMLHttpRequest();

    xhr.open('POST', '/WebPanelBlock/Submit');
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = function() {
      var data;

      if (xhr.status === 200) {
        data = JSON.parse(xhr.responseText);
        console.log(data);
      }
    };

    setTimeout(function() {
      xhr.send();
    }, 0);
  }

  var events = {
    _init: function() {
      if (/[?&]webpanel=/.test(location.search) && window.localStorage.getItem('sgf_webpanel_scrollTop') != null) {
        setTimeout(function() {
          var isChrome = navigator.userAgent.indexOf('Chrome') > -1;
          var isExplorer = navigator.userAgent.indexOf('MSIE') > -1;
          var isEdge = navigator.userAgent.indexOf('Edge') > -1;
          var isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
          var isSafari = navigator.userAgent.indexOf('Safari') > -1;
          var isOpera = navigator.userAgent.toLowerCase().indexOf('op') > -1;

          if ((isChrome) && (isSafari)) {
            isSafari = false;
          }

          if ((isChrome) && (isOpera)) {
            isChrome = false;
          }

          if (isExplorer || isSafari || isEdge) {
            window.document.body.scrollTop = window.localStorage.getItem('sgf_webpanel_scrollTop');
            console.log('window.document.body.scrollTop');
          }
          else {
            document.documentElement.scrollTop = window.localStorage.getItem('sgf_webpanel_scrollTop');
            console.log('document.documentElement.scrollTop');
          }

          localStorage.removeItem('sgf_webpanel_scrollTop');
        }, 0);
      }

      var golfId1 = context.element.querySelector('.web-panel-block__input--golfidpart1');
      var golfId2 = context.element.querySelector('.web-panel-block__input--golfidpart2');

      golfId1.onkeyup = function(event) {
        if (((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) && golfId1.value.length === 6) {
          golfId2.focus();
        }
      }

      golfId2.onkeyup = function(event) {
        if (((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) && golfId2.value.length === 3) {
          context.element.querySelector('.web-panel-block__input--password').focus();
        }
      }
    },

    _onclick: function(event, element, elementType) {
      var golfId1 = context.element.querySelector('.web-panel-block__input--golfidpart1');
      var golfId2 = context.element.querySelector('.web-panel-block__input--golfidpart2');
      var password = context.element.querySelector('.web-panel-block__input--password');

      if (elementType === 'submit') {
        var formValid = true;
        if (!validate(golfId1, /^\d{6}$/)) {
          formValid = false;
          event.preventDefault();
        }

        if (!validate(golfId2, /^\d{3}$/)) {
          formValid = false;
          event.preventDefault();
        }

        if (!validate(password)) {
          formValid = false;
          event.preventDefault();
        }

        if (formValid) {
          var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
          window.localStorage.setItem('sgf_webpanel_scrollTop', scrollPosition);
        }
      }
    }
  };

  return {
    init: events._init,
    onclick: events._onclick
  };
});
