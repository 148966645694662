/**
 * Facet
 * @overview Facet
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('facet', function(context) {
  'use strict';

  var _this;
  var moduleContainer;
  var config;

  var preventDefault = function(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
      event.cancelBubble = true;
    }
  };

  var resize = function() {
    var filters = moduleContainer.querySelectorAll('.facet').toArray();
    var browserWidth = window.innerWidth;

    filters.forEach(function(filter) {
      var heading = filter.querySelector('.facet__heading');
      var filterContainer = filter.querySelector('.facet__facets');
      var leftOffset;

      if (browserWidth > 1300) {
        leftOffset = -(Math.floor((filterContainer.offsetWidth - heading.offsetWidth) / 2));
        filterContainer.style.marginLeft = leftOffset + 'px';
      } else {
        filterContainer.style.marginLeft = 'auto';
      }
    });
  };

  var hide = function() {
    moduleContainer.querySelectorAll('.facet').toArray().forEach(function(filter) {
      var filterContainer = filter.querySelector('.facet__facets');
      var filterHeading = filter.querySelector('.facet__heading');
      filterContainer.classList.add('facet__facets--hidden');
      filterHeading.classList.remove('facet__heading--expanded');
    });
  }

  var events = {
    _init: function() {
      var headings;
      var filters;
      _this = this;
      moduleContainer = context.element;
      config = context.getConfig();

      headings = moduleContainer.querySelectorAll('.facet__heading--no-js').toArray();

      headings.forEach(function(filter) {
        filter.classList.remove('facet__heading--no-js');
      });

      filters = moduleContainer.querySelectorAll('.facet__facets--no-js').toArray();

      filters.forEach(function(filter) {
        filter.classList.remove('facet__facets--no-js');
      });

      resize();

      filters = moduleContainer.querySelectorAll('.facet').toArray();

      filters.forEach(function(filter) {
        var filterContainer = filter.querySelector('.facet__facets');
        filterContainer.classList.add('facet__facets--hidden');
      });
    },

    _destroy: function() {
      moduleContainer = null;
    },

    _onclick: function(event, element, elementType) {
      var filters;
      var isHidden;
      var e;

      if (elementType === 'toggle-facet') {
        event.stopPropagation();
        filters = element.parentNode.querySelector('.facet__facets');
        isHidden = filters.classList.contains('facet__facets--hidden');
        moduleContainer.querySelectorAll('.facet').toArray().forEach(function(filter) {
          var filterContainer = filter.querySelector('.facet__facets');
          filterContainer.classList.add('facet__facets--hidden');
        });

        if (isHidden) {
          filters.classList.remove('facet__facets--hidden');
          resize();
        }

        element.classList.toggle('facet__heading--expanded');
      } else if (elementType === 'select-facet-checkbox') {
        context.application.broadcast('facet-change', { namespace: config.namespace, name: element.name, selected: element.checked, value: element.value  });
      }
    },

    _onmessage: function(name, data) {
      switch (name) {
        case 'windowresize':
          resize();
          break;
        case 'page-click':
          hide();
          break;
      }
    }
  }

  NodeList.prototype.toArray = function() {
    return Array.prototype.slice.call(this);
  }

  return {
    behaviors: ['window-events'],
    messages: ['windowresize', 'page-click'],
    init: events._init,
    onmessage: events._onmessage,
    destroy: events._destroy,
    onclick: events._onclick
  };
});
