/**
 * Module for Golfens dag page
 * @overview Golfens dag page
 * @copyright (c) 2017
 * @author Team STHLM
 */

//SGF.Application.addModule('more-golf-page', function(context) {
//  'use strict';

//  var moduleContainer;
//  var moduleId;
//  var application = undefined;
//  var config;
//  var currentContentGuid;
//  var _this;
//  var infoWindows = [];
//  var whereAmIMarker;

//  NodeList.prototype.toArray = function() {
//    return Array.prototype.slice.call(this);
//  }

//  NodeList.prototype.first = function() {
//    return this[0];
//  }

//  var load = function() {
//    var apiService = context.getService('googlemaps-api');
//    apiService.load();
//  }

//  var isInternetExplorer = (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)));
//  var isFirefox = (window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf('Firefox') > 0);
//  var currentTime = 0;
//  var start = 0;
//  var change = 0;
//  var animationDuration = 1000;

//  var animateScroll = function(targetElement) {
//    if (targetElement) {
//      currentTime = 0;
//      start = window.pageYOffset || document.documentElement.scrollTop;
//      change = (window.pageYOffset + targetElement.getBoundingClientRect().top) - start;
//    }

//    var increment = 20;

//    currentTime += increment;
//    var val = easeInOutQuad(currentTime, start, change, animationDuration);

//    if (window.document.body.scrollTop !== undefined && !isInternetExplorer && !isFirefox) {
//      window.document.body.scrollTop = val;
//    }
//    else if (document.documentElement.scrollTop !== undefined) {
//      document.documentElement.scrollTop = val;
//    }

//    if (currentTime < animationDuration) {
//      setTimeout(animateScroll, increment);
//    }
//  }

//  var easeInOutQuad = function(t, b, c, d) {
//    t /= d / 2;
//    if (t < 1) return c / 2 * t * t + b;
//    t--;
//    return -c / 2 * (t * (t - 2) - 1) + b;
//  }

//  var addClickEvents = function() {
//    var linkMoveToText = document.querySelector('.golf-day-page__header__wrapper__readmore__link');
//    var linkMoveToMap = document.querySelector('.golf-day-page__header__wrapper__findclub__link');

//    linkMoveToText.addEventListener('click', function(event) {
//      animationDuration = 750;
//      animateScroll(document.querySelector('.golf-day-page__page-wrapper .free-text-block'));
//      event.preventDefault();
//    }, false);

//    linkMoveToMap.addEventListener('click', function(event) {
//      animationDuration = 500;
//      animateScroll(document.querySelector('.golf-day-page__map'));
//      event.preventDefault();
//    }, false);
//  }

//  var geoSearch = function(geocoder, resultsMap, queryString) {

//    var noHitsQueryElement = document.querySelector('.golf-day-page__search__nohits__text span');
//    noHitsQueryElement.innerText = queryString;

//    var mapElement = document.querySelector('.golf-day-page__map');
//    var xhr = new XMLHttpRequest();
//    var params = 'contentGuid=' + currentContentGuid;

//    if (queryString && queryString.length > 0) {
//      params += '&queryString=' + queryString;
//    }

//    xhr.open('POST', '/service/golfclub/getall', true);
//    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');

//    xhr.onload = function(e) {
//      var response = JSON.parse(xhr.responseText);
//      if (response.StatusCode === 'OK' && response.GolfClubs.length > 0) {

//        var club = response.GolfClubs[0];
//        if (club.Latitude && club.Latitude.length > 0 && club.Longitude && club.Longitude.length > 0) {
//          resultsMap.setCenter({
//            lng: parseFloat(club.Longitude),
//            lat: parseFloat(club.Latitude)
//          });

//          resultsMap.setZoom(10);
//          animationDuration = 500;
//          animateScroll(mapElement);
//        }
//      } else {
//        geoSearchGoogle(geocoder, resultsMap, queryString, mapElement);
//      }

//      if (response.StatusCode === 'Error' || response.StatusCode === 'Forbidden') {
//        geoSearchGoogle(geocoder, resultsMap, queryString, mapElement);
//      }
//    }

//    xhr.onerror = function() {
//      geoSearchGoogle(geocoder, resultsMap, queryString, mapElement);
//    }

//    xhr.send(params);
//  }

//  var geoSearchGoogle = function(geocoder, resultsMap, queryString, mapElement) {
//    geocoder.geocode({ address: queryString + ', sverige' }, function(results, status) {

//      animationDuration = 500;
//      animateScroll(mapElement);

//      if (status === 'OK') {
//        resultsMap.setCenter(results[0].geometry.location);
//        resultsMap.setZoom(10);
//      } else {

//        var noHitsText = document.querySelector('.golf-day-page__search__nohits');
//        noHitsText.classList.add('show');

//        setTimeout(function() {
//          noHitsText.classList.add('fade');
//          setTimeout(function() {
//            noHitsText.classList.remove('show');
//            noHitsText.classList.remove('fade');
//          }, 1500);
//        }, 5000);

//        console.warn('Geocode was not successful for the following reason: ', status);
//      }
//    });
//  }

//  var events = {
//    _init: function() {
//      _this = this;
//      moduleContainer = context.element;
//      moduleId = context.element.id;
//      application = context.application;
//      config = context.getConfig();
//      currentContentGuid = config.contentGuid;

//      document.querySelector('.framework-header').setAttribute('ignore-scroll', 'true');
//      document.querySelector('.framework-header').classList.add('framework-header--not-fixed');
//    },

//    _destroy: function() {
//      moduleId = null;
//      moduleContainer = null;
//    },

//    _onmessage: function(name, data) {
//      if (name === 'application-init') {
//        load();
//        addClickEvents();
//      }
//      else if (name === 'googlemaps-api.loaded') {

//        var xhr = new XMLHttpRequest();
//        var params = 'contentGuid=' + currentContentGuid;
//        var queryString = '';

//        if (queryString && queryString.length > 0) {
//          params += '&queryString=' + queryString;
//        }

//        xhr.open('POST', '/service/golfclub/getall', true);
//        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');

//        xhr.onload = function(e) {
//          var response = JSON.parse(xhr.responseText);
//          if (response.StatusCode === 'OK') {

//            var golfClubMarkers = [];
//            var golfClubs = response.GolfClubs.map(function(club, i) {
//              if (club.Latitude && club.Latitude.length > 0 && club.Longitude && club.Longitude.length > 0) {

//                let htmlString = '<div class="golf-day-page__map__marker-window"><strong>' + club.Heading + '</strong>';

//                if (club.StreetAddress && club.StreetAddress.length > 0)
//                  htmlString += '<p>' + club.StreetAddress + '</p>';
//                if (club.PostAddress && club.PostAddress.length > 0)
//                  htmlString += '<p>' + club.PostAddress + '</p>';
//                if (club.PhoneNumber && club.PhoneNumber.length > 0)
//                  htmlString += '<p class="phone"><strong>Tel:</strong> ' + club.PhoneNumber + '</p>';
//                if (club.Link && club.Link.length > 0 && club.LinkText && club.LinkText.length > 0)
//                  htmlString += '<p class="link"><a href="' + club.Link + '" target="_blank">' + club.LinkText + '</a></p>';

//                htmlString += '</div>';

//                golfClubMarkers.push(
//                {
//                  location: {
//                    lat: parseFloat(club.Latitude),
//                    lng: parseFloat(club.Longitude)
//                  },
//                  html: htmlString
//                });
//              }
//            });

//            var vpHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
//            var vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
//            var mapDefaultPos = { lat: 63.021629, lng: 17.079999 };
//            var zoomLevel = 5;
//            if (vpHeight < 670) {
//              zoomLevel = 4;
//              document.getElementById('map').classList.add('small-device');
//            }

//            if (vpWidth < 400) {
//              document.querySelector('.golf-day-page__search__textbox').placeholder = 'Sök klubb, postnr, ort';
//            }

//            var map = new google.maps.Map(document.getElementById('map'), mapSettings(zoomLevel, mapDefaultPos));

//            var golfDayLogo = document.querySelector('.golf-day-page__header__wrapper__image img');
//            var golfDayLogoMobile = document.querySelector('.golf-day-page__header__wrapper__mobile-image img');

//            golfDayLogo.addEventListener('click', function() {
//              map.setCenter(mapDefaultPos);
//              map.setZoom(zoomLevel);
//            });

//            golfDayLogoMobile.addEventListener('click', function() {
//              map.setCenter(mapDefaultPos);
//              map.setZoom(zoomLevel);
//            });

//            var markers = golfClubMarkers.map(function(club, i) {
//              var singleMarker = new google.maps.Marker({
//                position: club.location,
//                icon: {
//                  url: '/static/images/sprites/pages/golf-day-page/map-marker.png',
//                  anchor: new google.maps.Point(15, 37)
//                }
//              });

//              var infowindow = new google.maps.InfoWindow({
//                content: club.html
//              });

//              infoWindows.push(infowindow);

//              singleMarker.addListener('mouseover', function() {
//                for (var i = 0; i < infoWindows.length; i++) {
//                  infoWindows[i].close();
//                }

//                infowindow.open(map, singleMarker);
//              });

//              return singleMarker;
//            });

//            map.addListener('click', function() {
//              for (var i = 0; i < infoWindows.length; i++) {
//                infoWindows[i].close();
//              }
//            });

//            var clusterStyles = [
//              {
//                textColor: 'white',
//                url: '/static/images/sprites/pages/golf-day-page/m1.png',
//                height: 34,
//                width: 34,
//                textSize: 18
//              },
//              {
//                textColor: 'white',
//                url: '/static/images/sprites/pages/golf-day-page/m2.png',
//                height: 54,
//                width: 54,
//                textSize: 18
//              }
//            ];

//            var mcOptions = {
//              gridSize: 50,
//              styles: clusterStyles,
//              maxZoom: 15
//            };

//            var mc = new MarkerClusterer(map, markers, mcOptions);

//            var geocoder = new google.maps.Geocoder();
//            var textbox = document.querySelector('.golf-day-page__search__textbox');

//            document.querySelector('.golf-day-page__search__button').addEventListener('click',
//              function() {
//                if (textbox.value.length >= 3) {
//                  geoSearch(geocoder, map, textbox.value);
//                  map.setOptions({ scrollwheel: true });
//                }
//              });

//            textbox.addEventListener('keypress', function(e) {
//              var key = e.which || e.keyCode;
//              if (key === 13 && textbox.value.length >= 3) {
//                geoSearch(geocoder, map, textbox.value);
//                map.setOptions({ scrollwheel: true });
//              }
//            });

//            var mapWrapper = document.querySelector('#map');
//            mapWrapper.addEventListener('touchmove',
//              function(e) {
//                var twoFingerSwipeBgElem = document.querySelector('.gm-style-pbc');
//                if (twoFingerSwipeBgElem) {
//                  twoFingerSwipeBgElem.style.zIndex = '10';
//                }
//              }, false);

//            mapWrapper.addEventListener('touchend',
//              function(e) {
//                var twoFingerSwipeBgElem = document.querySelector('.gm-style-pbc');
//                if (twoFingerSwipeBgElem) {
//                  setTimeout(function() {
//                    twoFingerSwipeBgElem.style.zIndex = '2';
//                  }, 800);
//                }
//              }, false);

//            mapWrapper.addEventListener('click',
//              function(e) {
//                map.setOptions({ scrollwheel: true });
//              }, false);

//            if (navigator.geolocation) {
//              navigator.geolocation.getCurrentPosition(function(position) {
//                var pos = {
//                  lat: position.coords.latitude,
//                  lng: position.coords.longitude
//                };

//                whereAmIMarker = new google.maps.Marker({
//                  position: pos,
//                  icon: {
//                    url: '/static/images/sprites/pages/golf-day-page/map-marker-whereami.png',
//                    anchor: new google.maps.Point(15, 37)
//                  }
//                });

//                whereAmIMarker.setMap(map);
//                map.setCenter(pos);
//                map.setZoom(10);
//              });
//            }
//          }

//          if (response.StatusCode === 'Error') {
//            console.error('Error', xhr.responseText);
//          }

//          if (response.StatusCode === 'Forbidden') {
//            console.error('Forbidden', xhr.responseText);
//          }
//        }

//        xhr.onerror = function() {
//          console.error('Error', xhr.responseText);
//        }

//        xhr.send(params);
//      }
//    }
//  }

//  var mapSettings = function(zoomLevel, mapDefaultPos) {
//    var settings = {
//      zoom: zoomLevel,
//      center: mapDefaultPos,
//      scrollwheel: false,
//      gestureHandling: 'cooperative',
//      styles:
//      [
//        {
//          featureType: 'all',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'all',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              weight: '2.00'
//            },
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'all',
//          elementType: 'geometry.stroke',
//          stylers: [
//            {
//              color: '#93c021'
//            },
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'all',
//          elementType: 'labels.text',
//          stylers: [
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'all',
//          elementType: 'labels.text.stroke',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.country',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.country',
//          elementType: 'labels',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.province',
//          elementType: 'geometry',
//          stylers: [
//            {
//              visibility: 'off'
//            },
//            {
//              color: '#ff0000'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.province',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.locality',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.locality',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.locality',
//          elementType: 'labels',
//          stylers: [
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'administrative.land_parcel',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape',
//          elementType: 'all',
//          stylers: [
//            {
//              color: '#f2f2f2'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              color: '#ffffff'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape',
//          elementType: 'labels.text',
//          stylers: [
//            {
//              color: '#ff0000'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape',
//          elementType: 'labels.text.stroke',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape.man_made',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              color: '#ffffff'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape.natural',
//          elementType: 'labels.text',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'landscape.natural.landcover',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'poi',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'road',
//          elementType: 'all',
//          stylers: [
//            {
//              saturation: -100
//            },
//            {
//              lightness: 45
//            },
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'road',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              color: '#eded81'
//            },
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'road',
//          elementType: 'labels.text',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'road',
//          elementType: 'labels.text.fill',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'road',
//          elementType: 'labels.text.stroke',
//          stylers: [
//            {
//              color: '#ffffff'
//            }
//          ]
//        },
//        {
//          featureType: 'transit',
//          elementType: 'all',
//          stylers: [
//            {
//              visibility: 'off'
//            }
//          ]
//        },
//        {
//          featureType: 'water',
//          elementType: 'all',
//          stylers: [
//            {
//              color: '#46bcec'
//            },
//            {
//              visibility: 'on'
//            }
//          ]
//        },
//        {
//          featureType: 'water',
//          elementType: 'geometry.fill',
//          stylers: [
//            {
//              color: '#b0bfcc'
//            }
//          ]
//        },
//        {
//          featureType: 'water',
//          elementType: 'labels.text.fill',
//          stylers: [
//            {
//              color: '#070707'
//            }
//          ]
//        },
//        {
//          featureType: 'water',
//          elementType: 'labels.text.stroke',
//          stylers: [
//            {
//              color: '#ffffff'
//            }
//          ]
//        }
//      ]
//    };

//    return settings;
//  };

//  return {
//    behaviors: ['window-events'],
//    messages: ['application-init', 'googlemaps-api.loaded'],
//    init: events._init,
//    destroy: events._destroy,
//    onmessage: events._onmessage
//  };
//});
