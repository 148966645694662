SGF.Application.addModule('feedback-form', function(context) {
  'use strict';

  var caddyshack;
  var config = {};
  var container = null;
  var initialContainerValue = null;
  var contentId;

  function displayForm(feedbackLevelValue) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/Feedback/AddFeedback', true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');

    xhr.onload = function(e) {
      var response = xhr.responseText;
      if (response !== null) {
        container.innerHTML = response;
      }
    }

    var args = 'contentid=' + encodeURIComponent(contentId) + '&status=' + encodeURIComponent(feedbackLevelValue);
    xhr.send(args);
  };

  function postForm() {
    var form = context.element.getElementsByTagName('form')[0];
    var elements = form.elements;
    var args = [];

    for (var i = 0, element; element = elements[i++];) {
      if (element.type === 'submit') {
        continue;
      }

      if (element.name === '__RequestVerificationToken') {
        continue;
      }

      if (element.type === 'checkbox') {
        if (element.checked) {
          args.push(element.name + '=true');
        }

        continue;
      }

      if (!element.name) {
        continue;
      }

      args.push(element.name + '=' + encodeURIComponent(element.value));
    }

    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/Feedback/AddFeedback', true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.onload = function(e) {
      var response = xhr.responseText;

      if (response !== null) {
        container.innerHTML = response;
        container.scrollIntoView();
      }
    }

    var sendArgs = args.join('&');
    xhr.send(sendArgs);
  }

  function resetForm() {
    container.innerHTML = initialContainerValue;
  }

  return {
    init: function() {
      context.element.classList.remove('no-js');
      config = context.getConfig();
      caddyshack = context.application.getService('caddyshack');

      if (config) {
        if (config.id) {
          contentId = config.id;
        }
      }

      if (config.selectors.container !== undefined) {
        container = context.element.getElementsByClassName(config.selectors.container)[0];
        initialContainerValue = container.innerHTML;
      }
    },

    destroy: function() {
      config = null;
      container = null;
      initialContainerValue = null;
    },

    onclick: function(event, element, elementType) {
      if (elementType === 'action-button') {
        caddyshack.preventDefault(event);
        var dataValue = element.getAttribute('data-value');
        displayForm(dataValue);
      }

      if (elementType === 'form-submit') {
        caddyshack.preventDefault(event);
        postForm();
      }

      if (elementType === 'form-cancel') {
        caddyshack.preventDefault(event);
        resetForm();
      }
    }
  };
});
