/**
 * Masonry Newsletter Block
 * @overview For a masonry grid item
 * @copyright (c) 2015
 * @author Team Golf
 */
SGF.Application.addModule('masonry-newsletter-block', function(context) {
  'use strict';

  var config;
  var container;
  var moduleId;
  var moduleClass;
  var caddyshack;
  var messageName = 'masonry-grid-item-loaded';
  var formAction = {};
  var messageBox;
  var errorMessageBox;

  function broadcast() {
    context.application.broadcast(messageName, { id: moduleId });
  }

  function addSubscriber(obj) {

    if (obj === null || obj === undefined) {
      return;
    }

    var formData = {};
    for (var i = 0; i < obj.target.elements.length; i++) {
      var key = obj.target.elements[i].name;
      var value = obj.target.elements[i].value;
      formData[key] = value;
    }

    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/service/newsletter/add', true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');

    xhr.onload = function(e) {
      var response = JSON.parse(xhr.responseText);
      if (response.StatusCode === 'OK') {
        container.classList.remove(moduleClass + '--loading');
        container.classList.remove(moduleClass + '--error');
        container.classList.add(moduleClass + '--success');
        messageBox.innerHTML = '<p>' + response.Response + '</p>';
        context.application.broadcast('masonry-grid-reload');
      }

      if (response.StatusCode === 'Error') {
        container.classList.remove(moduleClass + '--loading');
        container.classList.add(moduleClass + '--error');
        errorMessageBox.innerHTML = '<p>' + response.Response + '</p>';
        context.application.broadcast('masonry-grid-reload');
      }

      if (response.StatusCode === 'Forbidden') {
        container.classList.remove(moduleClass + '--success');
        container.classList.remove(moduleClass + '--loading');
        container.classList.remove(moduleClass + '--error');
        context.application.broadcast('masonry-grid-reload');
      }
    }

    xhr.onerror = function() {
      container.classList.remove(moduleClass + '--loading');
      container.classList.add(moduleClass + '--error');
      errorMessageBox.innerHTML = '<p>Det uppstod ett fel. Vänligen försök igen.</p>';
      context.application.broadcast('masonry-grid-reload');
    }

    container.classList.remove(moduleClass + '--error');
    container.classList.remove(moduleClass + '--success');
    container.classList.add(moduleClass + '--loading');
    context.application.broadcast('masonry-grid-reload');

    xhr.send('query=' + JSON.stringify(formData) + '&url=' + formAction);
  }

  return {
    init: function() {
      caddyshack = context.application.getService('caddyshack');
      moduleId = context.element.id;
      container = context.element;
      config = context.getConfig();
      moduleClass = container.classList[0];

      messageBox = container.getElementsByClassName(moduleClass + '__message')[0];
      errorMessageBox = container.getElementsByClassName(moduleClass + '__error-message')[0];

      var form = container.getElementsByTagName('form')[0];
      if (form !== undefined && form !== null) {
        formAction = form.getAttribute('action');
      };

      broadcast();
    },

    onsubmit: function(event, element, elementType) {
      caddyshack.preventDefault(event);
      addSubscriber(event);
    },

    destroy: function() {
      config = null;
      container = null;
    }
  }
});
